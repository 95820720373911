import React, { useState, useEffect } from "react";
import { Input, Space, List } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

import Button from "../button/Button";
import "./CommentMaker.styles.css";

const CommentMaker = ({
  onChange = () => {},
  initialValues = [],
  ticketNumber,
  lastLineNumber,
  setLastLineNumber,
}) => {
  const [comments, setComments] = useState(initialValues);

  useEffect(() => {
    onChange(comments);
  }, [comments]);

  const handleAddComment = () => {
    setComments([
      ...comments,
      {
        Description: "",
        LineNumber: lastLineNumber + 1000,
        No: null,
        ticketNumber: ticketNumber,
        type: "comment",
        key: uuidv4(),
      },
    ]);
    setLastLineNumber(lastLineNumber + 1000);
  };

  const handleCommentChange = (index, value) => {
    const updatedComment = { ...comments[index] };

    updatedComment.Description = value;

    const newComments = [...comments];
    newComments[index] = updatedComment;

    setComments(newComments);
  };

  const handleDeleteComment = (index) => {
    const newComments = [...comments];
    newComments.splice(index, 1);
    setComments(newComments);
  };

  return (
    <div className="comment__maker__container">
      <span className="comment__maker__title">Comments</span>
      <List
        locale={{ emptyText: "No Comments" }}
        bordered
        dataSource={comments}
        renderItem={(comment, index) => (
          <List.Item>
            <div className="comment__maker__comment">
              <Input
                value={comment.Description}
                onChange={(e) => handleCommentChange(index, e.target.value)}
                placeholder="Enter your comment"
                maxLength={100}
                showCount
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteComment(index)}
                danger
              >
                Delete
              </Button>
            </div>
          </List.Item>
        )}
      />
      <Button
        icon={<PlusCircleOutlined />}
        onClick={handleAddComment}
        className="add__comment__button"
      >
        Add Comment
      </Button>
    </div>
  );
};

export default CommentMaker;
