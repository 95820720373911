import "./error-screen.styles.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Result, Typography, Alert } from "antd";

const { Paragraph, Text } = Typography;

const ErrorScreen = (props) => {
  return (
    <div className="error__hub">
      <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          {props.errors.map((error) => {
            return (
              <div className="error__container">
                <Alert
                  message={`Item: ${error.number ? error.number : "N/A"} Line:${
                    error.lineNumber ? error.lineNumber : "N/A"
                  }`}
                  description={error.msg}
                  type="error"
                  showIcon
                />
              </div>
            );
          })}
        </div>
      </Result>
    </div>
  );
};

export default ErrorScreen;
