export const CONNECT_WITH_BAD_CREDENTIALS = 'connect-with-bad-credentials';
export const CONNECT_WITH_SSO = 'connect-with-sso';
export const UPDATE_REVENUES = 'update-revenues';
export const DISCONNECT = 'disconnect';
export const SHOW_SECRET = 'show-secret';
export const CHECK_INDICATOR = 'checkindicator';

export interface User {
    domain: string;
    name: string;
    displayName: string;
    groups: string[];
    sid: string;
    adUser?: any;
    // mainColumns:[];
    // subColumns:[];
    // itemColumn:[];
}


export interface RevenueTotal {
    pending: string;
    Approved: string;
    rejected: string;
    total: string
}


export interface CheckOrNo {
    checked: boolean;
}



interface UpdateCheckOrNo {
    type: typeof CHECK_INDICATOR;
    checked: CheckOrNo;
  }



interface ConnectWithSSOAction {
    type: typeof CONNECT_WITH_SSO;
    user: User;
}

interface UPDATEREVENUES {
    type: typeof UPDATE_REVENUES;
    revenue: RevenueTotal;
}

interface ConnectWithCredentialsAction {
    type: typeof CONNECT_WITH_BAD_CREDENTIALS;
}

interface ShowSecretAction {
    type: typeof SHOW_SECRET;
    secret: string;
}

interface DisconnectAction {
    type: typeof DISCONNECT;
}

export type AppAction =
    | ConnectWithSSOAction
    | ConnectWithCredentialsAction
    | ShowSecretAction
    | DisconnectAction
    | UpdateCheckOrNo
    | UPDATEREVENUES




export interface UserState {
    user?: User;
}

export interface RevenueState {
    revenue?: RevenueTotal;
}

export interface SecretState {
    secret: string;
}

export interface ErrorState {
    error: boolean;
}

export interface CheckState {
    check?: CheckOrNo;
}

export interface AppState extends CheckState, UserState, SecretState, RevenueState,ErrorState { }

export interface ConnectDispatch {
    disconnect: () => void;
}
