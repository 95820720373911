import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  UpCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./StatusBadge.styles.css";

const StatusBadge = ({ text, type = "", style }) => {
  const getBackgroundColor = (type) => {
    switch (type.toUpperCase()) {
      case "PENDING":
        return "#F1C40F";
      case "APPROVED":
        return "#2ECC71";
      case "FIELD MANAGER APPROVED":
        return "#9B59B6";
      case "TOTAL":
        return "#3498DB";
      default:
        return "black";
    }
  };

  const getColor = (type) => {
    switch (type.toUpperCase()) {
      case "PENDING":
        return "#000000";
      case "APPROVED":
        return "#ffffff";
      case "FIELD MANAGER APPROVED":
        return "#ffffff";
      case "TOTAL":
        return "#ffffff";
      case "REJECTED":
        return "#ffffff";
      default:
        return "#ffffff";
    }
  };

  const getIcon = (type) => {
    switch (type.toUpperCase()) {
      case "PENDING":
        return <ExclamationCircleOutlined />;
      case "APPROVED":
        return <CheckCircleOutlined />;
      case "FIELD MANAGER APPROVED":
        return <UpCircleOutlined />;
      case "TOTAL":
        return "Total";
      case "REJECTED":
        return <CloseCircleOutlined />;
      default:
        return null;
    }
  };

  return (
    <div
      className="status__badge"
      style={{
        ...style,
        background: getBackgroundColor(type),
        color: getColor(type),
      }}
    >
      <span
        className="icon__spacing"
        style={{
          stroke: getColor(type),
        }}
      >
        {getIcon(type)}
      </span>
      <span>{text.toUpperCase()}</span>
    </div>
  );
};

export default StatusBadge;
