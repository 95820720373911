import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store, persistor } from "../src/redux2/store";
import enUS from "antd/lib/locale/en_US";
import { ConfigProvider } from "antd";
import { PersistGate } from "redux-persist/integration/react";
import swDev from "./swDev.js";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ConfigProvider locale={enUS}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
