import "./Button.styles.css";

const Button = ({ children, icon, onClick, style, disabled }) => {
  return (
    <div
      className={`field__ticket__button ${disabled ? "disabled" : ""}`}
      onClick={disabled ? null : onClick}
      style={style}
    >
      {icon ? <div className="field__ticket__button__icon">{icon}</div> : null}
      {children ? (
        <div className="field__ticket__button__text">{children}</div>
      ) : null}
    </div>
  );
};

export default Button;
