import React, { useState } from "react";
import { Table, Input, DatePicker, Select } from "antd";
import { useHistory } from "react-router-dom";
import Button from "../button/Button";
import {
  EditOutlined,
  CloudUploadOutlined,
  GiftOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { getUploadedFiles } from "./FieldTicketTable.service";
import StatusBadge from "../status-badge/StatusBadge.component";
import { connect } from "react-redux";
import "./FieldTicketTable.styles.css";
import { store } from "../../redux2/store";
import { UPDATE_REVENUES } from "../../redux2/types";
import dayjs from "dayjs";
import { compareInsertTime } from "../../functions/compareInsertTime";
import { toast } from "react-toastify";

const { RangePicker } = DatePicker;

const FieldTicketTable = ({
  setSelectedRow,
  setShipModalVisible,
  user,
  dropdownData = [],
  recordData = [],
}) => {
  const history = useHistory();
  const [searchValues, setSearchValues] = useState([]);

  const handleInputChange = (value, dataIndex) => {
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [dataIndex]: value,
    }));
  };

  const handleDateFilter = (dates, dataIndex) => {
    if (dates && dates.length === 2) {
      setSearchValues((prevSearchValues) => ({
        ...prevSearchValues,
        [dataIndex]: dates,
      }));
    } else {
      setSearchValues((prevSearchValues) => ({
        ...prevSearchValues,
        [dataIndex]: null,
      }));
    }
  };

  const generateColumnHeaderInput = (title, dataIndex) => {
    return () => (
      <div className="field__ticket__table__column__header">
        <h6>{title}</h6>
        <div>
          <Input
            value={searchValues[dataIndex]}
            onChange={(e) => handleInputChange(e.target.value, dataIndex)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    );
  };

  const generateColumnHeaderDatePicker = (title, dataIndex) => {
    return () => (
      <div className="field__ticket__table__column__header">
        <h6>{title}</h6>
        <div>
          <RangePicker
            onChange={(dates) => handleDateFilter(dates, dataIndex)}
          />
        </div>
      </div>
    );
  };

  const generateColumnHeaderSelectBadges = (title, dataIndex, options) => {
    return () => (
      <div className="field__ticket__table__column__header">
        <h6>{title}</h6>
        <div>
          <Select
            value={searchValues[dataIndex]}
            allowClear
            onChange={(value) => {
              handleInputChange(value, dataIndex);
            }}
            dropdownStyle={{ minWidth: "300px" }}
            style={{ minWidth: "100px" }}
          >
            {options.map(({ text, value }) => (
              <Select.Option
                key={value}
                value={value}
                style={{ lineHeight: 0 }}
              >
                <div>
                  <StatusBadge
                    text={text}
                    type={value}
                    style={{ lineHeight: 0 }}
                  />
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  const generateColumnHeaderSelect = (
    title,
    dataIndex,
    options,
    valueField,
    labelField
  ) => {
    return () => (
      <div className="field__ticket__table__column__header">
        <h6>{title}</h6>
        <div>
          <Select
            showSearch
            allowClear
            value={searchValues[dataIndex]}
            onChange={(value) => {
              handleInputChange(value, dataIndex);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            dropdownStyle={{ minWidth: "300px" }}
            style={{ minWidth: "100px" }}
          >
            {options.map((elem) => (
              <Select.Option key={elem[valueField]} value={elem[valueField]}>
                {elem[labelField]}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: generateColumnHeaderInput("Field Ticket #", "ticketNumber"),
      dataIndex: "ticketNumber",
      key: "ticketNumber",
      align: "center",
      width: "10%",
    },
    {
      title: generateColumnHeaderSelectBadges("Status", "status", [
        { text: "PENDING", value: "pending" },
        { text: "APPROVED", value: "Approved" },
        { text: "FIELD MANAGER APPROVED", value: "Field Manager Approved" },
      ]),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return <StatusBadge text={text} type={text} />;
      },
      align: "center",
      width: "10%",
    },
    {
      title: generateColumnHeaderInput("UWI", "uwi"),
      dataIndex: "uwi",
      key: "uwi",
      align: "center",
      width: "12%",
    },
    {
      title: generateColumnHeaderSelect(
        "Customer",
        "customerName",
        dropdownData.customers || [],
        "Name",
        "Name"
      ),
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      sorter: (a, b) => {
        if (a.customerName < b.customerName) {
          return -1;
        } else {
          return 1;
        }
      },
      width: "20%",
    },
    {
      title: generateColumnHeaderSelect(
        "Branch",
        "branch",
        dropdownData.branches || [],
        "code",
        "code"
      ),
      dataIndex: "branch",
      key: "branch",
      align: "center",
      sorter: (a, b) => {
        if (a.Branch < b.Branch) {
          return -1;
        } else {
          return 1;
        }
      },
      width: "4%",
    },
    {
      title: generateColumnHeaderDatePicker("Post Date", "postDate"),
      dataIndex: "postDate",
      key: "postDate",
      render: (text, record) => {
        return dayjs(text).format("YYYY-MM-DD");
      },
      align: "center",
    },
    {
      title: generateColumnHeaderInput("Reviewed By", "approvedsalesperson"),
      dataIndex: "approvedsalesperson",
      key: "approvedsalesperson",
      align: "center",
      sorter: (a, b) => {
        if (a.approvedsalesperson < b.approvedsalesperson) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      title: generateColumnHeaderSelect(
        "Salesperson",
        "sales_person",
        dropdownData.salespersons || [],
        "Code",
        "Code"
      ),
      dataIndex: "sales_person",
      key: "sales_person",
      align: "center",
    },
    {
      title: " ",
      dataIndex: "",
      key: "",
      width: "10%",
      render: (text, record) => {
        return (
          <div className="field__ticket__table__actions">
            {user.role !== "viewer" && (
              <>
                <Button
                  disabled={record.status === "Approved"}
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(record);
                  }}
                >
                  Edit
                </Button>
                <Button
                  disabled={record.status === "Approved"}
                  icon={<CloudUploadOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpload(record);
                  }}
                >
                  Upload
                </Button>
                <Button
                  disabled={record.status !== "Approved"}
                  icon={<GiftOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onShip(record);
                  }}
                  style={{ background: record.shipped ? "#2ECC71" : "#3498db" }}
                >
                  Ship
                </Button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const onView = async (record) => {
    const id = toast.loading("Please wait...", {
      position: "bottom-right",
      theme: "dark",
    });
    try {
      let recordCopy = { ...record };

      const uploadedFiles = await getUploadedFiles(recordCopy);
      toast.update(id, {
        render: "Loaded Successfully",
        type: "success",
        isLoading: false,
        theme: "dark",
        autoClose: 2000,
        position: "bottom-right",
      });
      let buff = uploadedFiles;

      history.push({
        pathname: "/field-ticket-viewer",
        state: { navdata: recordCopy, buff: buff },
      });
    } catch (error) {
      toast.update(id, {
        render: "File not found",
        type: "error",
        theme: "dark",
        isLoading: false,
        autoClose: 2000,
        position: "bottom-right",
      });
    }
  };

  const onEdit = (record) => {
    let recordCopy = { ...record };

    history.push({
      pathname: "./edit-ticket",
      state: { ticketData: recordCopy, mode: "edit" },
    });
  };

  const onUpload = (record) => {
    let recordCopy = { ...record };
    history.push({
      pathname: "./Uploader",
      state: { rowData: recordCopy },
    });
  };

  const onShip = (record) => {
    setShipModalVisible(true);
    setSelectedRow(record);
  };

  const filterData = () => {
    let filteredData = [...recordData];

    Object.keys(searchValues).forEach((key) => {
      const value = searchValues[key];
      if (value !== undefined && value !== null) {
        if (key === "status") {
          filteredData = filteredData.filter(
            (item) => String(item[key]) === value
          );
        } else if (Array.isArray(value)) {
          filteredData = filteredData.filter((item) => {
            const itemDate = new Date(item[key]);
            return (
              itemDate >= value[0].startOf("day") &&
              itemDate <= value[1].endOf("day")
            );
          });
        } else {
          filteredData = filteredData.filter((item) =>
            String(item[key]).toLowerCase().includes(value.toLowerCase())
          );
        }
      }
    });
    filteredData.sort(compareInsertTime);
    updateRevenues(filteredData);

    return filteredData;
  };

  // Function to update revenues
  const updateRevenues = (filteredData) => {
    const map1 = new Map();
    map1.set("Approved", 0);
    map1.set("rejected", 0);
    map1.set("pending", 0);

    map1.set("fieldManagerApproved", 0);
    map1.set("total", 0);

    for (var i = 0; i < filteredData.length; i++) {
      if (filteredData[i].total != undefined || filteredData[i].total != null) {
        if (filteredData[i].status === "Approved") {
          map1.set(
            "Approved",
            map1.get("Approved") + parseFloat(filteredData[i].total)
          );
        } else if (filteredData[i].status === "Rejected") {
          map1.set(
            "rejected",
            map1.get("rejected") + parseFloat(filteredData[i].total)
          );
        } else if (filteredData[i].status === "pending") {
          map1.set(
            "pending",
            map1.get("pending") + parseFloat(filteredData[i].total)
          );
        } else if (filteredData[i].status === "Field Manager Approved") {
          map1.set(
            "fieldManagerApproved",
            map1.get("fieldManagerApproved") + parseFloat(filteredData[i].total)
          );
        }
        map1.set(
          "total",
          map1.get("total") + parseFloat(filteredData[i].total)
        );
      }
    }

    store.dispatch({
      type: UPDATE_REVENUES,
      revenue: Object.fromEntries(map1),
    });
  };

  return (
    <Table
      bordered
      dataSource={recordData && filterData()}
      className="main__table"
      columns={columns}
      pagination={{ position: ["bottomCenter"] }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            onView(record);
          },
        };
      }}
    ></Table>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(FieldTicketTable);
