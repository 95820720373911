import jsPDF from "jspdf";
import "jspdf-autotable";
import headerImage from "../assets/ticketHeader.png";
import { formatCurrency } from "./formatCurrency";
import {
  calculateTotal,
  calculateDiscountAmount,
  calculateSalesTax,
  calculateTotalWithDiscount,
} from "./salesCalculations";
import { sortByLineNumber } from "./sortByLineNumber";
import dayjs from "dayjs";

//Page settings
const LEFT_MARGIN = 5;
const TOP_MARGIN = 5;
const RIGHT_MARGIN = 5;
const BOTTOM_MARGIN = 5;
const PAGE_WIDTH = 210;
const PAGE_HEIGHT = 297;
const USABLE_PAGE_WIDTH = PAGE_WIDTH - LEFT_MARGIN - RIGHT_MARGIN;
const USABLE_PAGE_HEIGHT = PAGE_HEIGHT - TOP_MARGIN - BOTTOM_MARGIN;

//Other page settings
const HEADER_HEIGHT = 24;
const BORDER_LINE_WIDTH = 2;
const BORDER_COLOR = [0, 0, 0];
const SECTION_GAP = 5;

//Content settings
const LEFT_CONTENT_MARGIN = 10;
const RIGHT_CONTENT_MARGIN = 10;
const BOTTOM_CONTENT_MARGIN = 10;
const TOP_CONTENT_MARGIN = 10;
const LINE_HEIGHT = 5;
const FONT_SIZE = 11;
const LINE_SPACING = 1;
const TICKET_INFORMATION_MAX_HEIGHT = 35;
const TICKET_DISCLAIMER_MAX_HEIGHT = 10;
const TICKET_SIGNATURES_MAX_HEIGHT = 20;
const TICKET_TOTALS_MAX_HEIGHT = 60;

const SIGNATURE_LINE_WIDTH = 1;
const SIGNATURE_LABEL_SPACE = 5;

const SIGNATURE_LINE_PADDING = 5;

const TICKET_DISCLAIMER_FONT_SIZE = 8;

const TICKET_DIVIDER_PADDING = 10;
const TICKET_DIVIDER_MAX_HEIGHT = 3;
const TICKET_DIVIDER_LINE_WIDTH = 0.2;

const generatePdf = (data) => {
  const doc = new jsPDF("p", "mm", [PAGE_WIDTH, PAGE_HEIGHT]);
  let pages = 1;

  let cursor = { x: 0, y: 0 };

  const drawBorder = () => {
    doc.setLineWidth(BORDER_LINE_WIDTH);
    doc.setDrawColor(BORDER_COLOR[0], BORDER_COLOR[1], BORDER_COLOR[2]);
    doc.rect(LEFT_MARGIN, TOP_MARGIN, USABLE_PAGE_WIDTH, USABLE_PAGE_HEIGHT);
  };

  const addProductTable = (data, xStart, yStart) => {
    const tableData = [];

    doc.setFontSize(FONT_SIZE);

    for (let i = 0; i < data.length; i++) {
      tableData.push([
        `${data[i].No || ""}`,
        `${data[i].Description || ""}`,
        `${data[i].Inventory || ""}`,
        `${data[i].UOM || ""}`,
        `${data[i].Unit_Cost ? formatCurrency(Number(data[i].Unit_Cost)) : ""}`,
        `${
          data[i].Unit_Cost && data[i].Inventory
            ? formatCurrency(
                Number(data[i].Unit_Cost) * Number(data[i].Inventory)
              )
            : ""
        }`,
      ]);
    }

    // Add the table to the current page

    doc.autoTable({
      startY: cursor.y + 10,
      // margin: {
      //   bottom:
      //     TICKET_DISCLAIMER_MAX_HEIGHT +
      //     TICKET_SIGNATURES_MAX_HEIGHT +
      //     TICKET_TOTALS_MAX_HEIGHT,

      //   top: 10,
      // },
      head: [["SPN", "Description", "Quantity", "UOM", "Price", "Amount"]],
      body: tableData,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
      },
      columnStyles: {
        0: { minCellWidth: 25 },
        2: { minCellWidth: 20 },
        3: { minCellWidth: 30 },
        4: { minCellWidth: 30 },
        5: { minCellWidth: 30 },
      },
      headStyles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },

      showHead: "everyPage",
      willDrawPage: (data) => {
        drawBorder();
      },
      didDrawPage: (data) => {
        pages++;
        cursor = data.cursor;
      },
      rowPageBreak: "avoid",
      pageBreak: "auto",
    });
  };

  const ticketInformation = (data, doc, xStart, yStart) => {
    var tableData = [];
    var tableData2 = [];

    var yAfterTable1 = 0;
    var yAfterTable2 = 0;

    tableData.push([
      "Address:",
      `${data.customer.Address} ${data.customer.City}, ${data.customer.Post_Code}`,
    ]);
    tableData.push(["Customer:", `${data.customerName}`]);
    tableData.push(["Salesperson:", `${data.sales_person}`]);
    tableData.push(["Steelhaus Rep:", `${data.steelhausRep}`]);
    tableData.push(["Customer Rep:", `${data.customerRep}`]);

    tableData2.push(["Ticket:", `${data.ticketNumber}`]);
    tableData2.push(["Date:", `${dayjs(data.dueDate).format("MM/DD/YYYY")}`]);
    tableData2.push(["UWI:", `${data.uwi}`]);
    tableData2.push(["Operation:", `${data.operation}`]);
    tableData2.push(["AFE:", `${data.afe || data.cost_center_AFE || "N/A"}`]);
    tableData2.push(["PO:", `${data.customer_PO_no || "N/A"}`]);

    doc.autoTable({
      startY: yStart,
      margin: {
        top: 10,
        right: PAGE_WIDTH / 2,
      },
      showHead: "never",
      columnStyles: {
        0: { cellWidth: 30 },
      },

      body: tableData,
      theme: "plain",
      styles: {
        lineColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
      tableWidth: PAGE_WIDTH / 2 - 30,

      showHead: "everyPage",
      didDrawPage: (data) => {
        yAfterTable1 = data.cursor.y;
      },
    });

    doc.setPage(0);

    doc.autoTable({
      startY: yStart,
      margin: {
        top: TOP_CONTENT_MARGIN,
        left: PAGE_WIDTH / 2 - 10,
      },
      showHead: "never",
      columnStyles: {
        0: { cellWidth: 30 },
      },

      body: tableData2,
      theme: "plain",
      styles: {
        lineColor: [0, 0, 0],
      },

      headStyles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },

      tableWidth: PAGE_WIDTH / 2,

      showHead: "everyPage",
      didDrawPage: (data) => {
        yAfterTable2 = data.cursor.y;
      },
    });

    if (yAfterTable1 > yAfterTable2) {
      cursor.y = yAfterTable1;
    } else {
      cursor.y = yAfterTable2;
    }
  };

  const ticketTotals = (data, doc, yStart) => {
    let subTotal = formatCurrency(calculateTotal(data.selectedProducts));
    let discount = data.discount || 0;
    let discountAmount =
      discount > 0
        ? formatCurrency(
            calculateDiscountAmount(
              calculateTotal(data.selectedProducts),
              discount
            )
          )
        : formatCurrency(0);
    let salesTaxes = formatCurrency(
      calculateSalesTax(calculateTotal(data.selectedProducts))
    );
    let total =
      discount > 0
        ? formatCurrency(
            calculateTotalWithDiscount(data.selectedProducts, discount)
          )
        : formatCurrency(calculateTotalWithDiscount(data.selectedProducts, 0));

    const textGroup = [
      `Sub Total: ${subTotal}`,
      `Discount: %${discount}`,
      `Discount Amount: ${discountAmount}`,
      `Sales Taxes: ${salesTaxes}`,
      `Total: ${total}`,
    ];

    doc.setFontSize(FONT_SIZE);

    //Add text group to document
    for (let i = 1; i <= textGroup.length; i++) {
      doc.text(
        textGroup[i - 1],
        PAGE_WIDTH - RIGHT_MARGIN - RIGHT_CONTENT_MARGIN,
        LINE_SPACING * i + yStart + LINE_HEIGHT * i,
        { align: "right" }
      );
    }
  };

  const ticketSignatures = (doc, yStart) => {
    doc.setLineWidth(SIGNATURE_LINE_WIDTH);

    const numSections = 3;
    const signatureLineLength =
      (USABLE_PAGE_WIDTH - RIGHT_CONTENT_MARGIN - LEFT_CONTENT_MARGIN) /
      numSections;

    for (let i = 0; i < numSections; i++) {
      const sectionStartX =
        LEFT_MARGIN +
        BORDER_LINE_WIDTH +
        LEFT_CONTENT_MARGIN +
        i * signatureLineLength;
      const sectionLabel = ["Client Signature", "Print Name", "Date"][i];

      // Draw section line
      doc.line(
        sectionStartX + SIGNATURE_LINE_PADDING,
        yStart,
        sectionStartX + signatureLineLength - SIGNATURE_LINE_PADDING,
        yStart
      );

      // Add section label
      doc.text(
        sectionLabel,
        sectionStartX + signatureLineLength / 2,
        yStart + SIGNATURE_LINE_WIDTH + SIGNATURE_LABEL_SPACE,
        {
          align: "center",
          maxWidth: signatureLineLength,
        }
      );
    }
  };

  const ticketDisclaimer = (doc, yStart) => {
    const text = `THE SERVICES, EQUIPMENT, MATERIALS AND/OR PRODUCTS COVERED BY THIS FIELD TICKET HAVE BEEN PERFORMED OR RECEIVED AS SET FORTH ABOVE AND PROVIDED AT THE PRICES SHOWN HEREIN PURSUANT TO THE MASTER SERVICE AGREEMENT ("MSA") BETWEEN CUSTOMER AND STEELHAUS AND, IN THE ABSENCE OF AN ACTIVE MSA, PURSUANT TO THE STEELHAUS GENERAL TERMS AND CONDITIONS`;

    doc.setFontSize(TICKET_DISCLAIMER_FONT_SIZE);

    doc.text(text, PAGE_WIDTH / 2, yStart, {
      align: "center",
      maxWidth:
        PAGE_WIDTH -
        LEFT_MARGIN -
        RIGHT_MARGIN -
        BORDER_LINE_WIDTH * 2 -
        LEFT_CONTENT_MARGIN -
        RIGHT_CONTENT_MARGIN,
    });
  };

  const ticketDivider = (doc, yStart) => {
    // Draw section line
    doc.setLineWidth(TICKET_DIVIDER_LINE_WIDTH);
    doc.line(
      LEFT_MARGIN + LEFT_CONTENT_MARGIN + TICKET_DIVIDER_PADDING,
      yStart,
      PAGE_WIDTH - RIGHT_MARGIN - RIGHT_CONTENT_MARGIN - TICKET_DIVIDER_PADDING,
      yStart
    );
  };

  ////////////////////////////////////////////////Rendering Code

  doc.setFontSize(FONT_SIZE);
  doc.addImage(
    headerImage,
    "JPEG",
    LEFT_MARGIN + BORDER_LINE_WIDTH,
    TOP_MARGIN + BORDER_LINE_WIDTH,
    USABLE_PAGE_WIDTH - BORDER_LINE_WIDTH * 2,
    HEADER_HEIGHT
  );

  ticketInformation(
    data,
    doc,
    LEFT_MARGIN + LEFT_CONTENT_MARGIN + BORDER_LINE_WIDTH,
    TOP_MARGIN + BORDER_LINE_WIDTH + HEADER_HEIGHT + SECTION_GAP
  );

  addProductTable(
    sortByLineNumber([...data.selectedProducts, ...data.comments]),
    LEFT_MARGIN + LEFT_CONTENT_MARGIN + BORDER_LINE_WIDTH,
    TICKET_INFORMATION_MAX_HEIGHT +
      TOP_MARGIN +
      BORDER_LINE_WIDTH +
      HEADER_HEIGHT +
      SECTION_GAP
  );

  if (
    cursor.y +
      TICKET_TOTALS_MAX_HEIGHT +
      TICKET_SIGNATURES_MAX_HEIGHT +
      TICKET_DISCLAIMER_MAX_HEIGHT >
    PAGE_HEIGHT - BOTTOM_MARGIN
  ) {
    doc.addPage([PAGE_WIDTH, PAGE_HEIGHT], "p");
    drawBorder();
    ticketTotals(data, doc, TOP_MARGIN + TOP_CONTENT_MARGIN);
  } else {
    ticketTotals(data, doc, cursor.y);
  }

  ticketSignatures(
    doc,

    PAGE_HEIGHT -
      BOTTOM_MARGIN -
      BOTTOM_CONTENT_MARGIN -
      TICKET_DISCLAIMER_MAX_HEIGHT -
      TICKET_DIVIDER_MAX_HEIGHT -
      TICKET_SIGNATURES_MAX_HEIGHT -
      SECTION_GAP
  );
  ticketDivider(
    doc,
    PAGE_HEIGHT -
      BOTTOM_MARGIN -
      BOTTOM_CONTENT_MARGIN -
      TICKET_DISCLAIMER_MAX_HEIGHT -
      TICKET_DIVIDER_MAX_HEIGHT -
      SECTION_GAP
  );
  ticketDisclaimer(
    doc,
    PAGE_HEIGHT -
      BOTTOM_MARGIN -
      BOTTOM_CONTENT_MARGIN -
      TICKET_DISCLAIMER_MAX_HEIGHT
  );

  doc.save("print.pdf");
};

export default generatePdf;
