import { Table } from "antd";
import { Divider } from "primereact/divider";

import "./cart-table.styles.css";

const { Column } = Table;

const CartTable = (props) => {
  return (
    <div className="cart__table--custom">
      <p className="cart__text">Parts & Service Charges</p>
      <Divider></Divider>
      <Table
        dataSource={props.cartData ? props.cartData : []}
        pagination={false}
      >
        <Column title="No." dataIndex="No" key="No"></Column>
        <Column
          title="Description"
          dataIndex="Description"
          key="Description"
        ></Column>
        <Column
          title="Quantity Shipped"
          dataIndex="Quantity_Shipped"
          key="Quantity_Shipped"
        ></Column>
        <Column
          title="Quantity Outstanding"
          dataIndex="QtyOutstanding"
          key="QtyOutstanding"
        ></Column>
      </Table>
    </div>
  );
};

export default CartTable;
