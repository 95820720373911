import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { Spin, ConfigProvider } from "antd";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "animate.css";

import Homepage from "./pages/home-page/homepage.component";
import Loginpage from "./pages/login/Loginpage.component";
import RequestResetPasswordPage from "./pages/request-reset-password/RequestResetPassword.component";
import RegisterPage from "./pages/register/register.component";
import ResetPasswordPage from "./pages/reset-password/ResetPassword.component";
import Uploader from "./components/uploader/Uploader";
import FieldTicketViewer from "./pages/field-ticket-viewer/FieldTicketViewer";
import SalesPersonGroups from "./pages/salesperson-groups/SalespersonGroups.jsx";
import EditTicket from "./pages/edit-ticket/EditTicket.page";
import LoadingScreen from "./components/loading-screen/loading-screen.component";
import { ToastContainer, toast } from "react-toastify";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "./index.css";
import "./unsorted/field.css";
import "./unsorted/FieldTicketStyle.css";
import "./unsorted/Form.css";
import "./unsorted/FormReviewStyle.css";
import "./unsorted/style.css";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary.component";
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";

// Create a client
const queryClient = new QueryClient();

const App = ({ user }) => {
  useEffect(() => {
    Spin.setDefaultIndicator(<LoadingScreen />);
  }, []);

  const isUserThere = user
    ? new Date(localStorage.getItem("user")) > new Date() &&
      localStorage.getItem("user") !== null
    : false;

  return (
    <>
      <ErrorBoundary fallback="There was an error">
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Geist",
              },
            }}
          >
            <ToastContainer />
            <Router>
              <div
                style={{
                  position: "fixed",
                  bottom: "16px",
                  left: "16px",
                  opacity: "50%",
                }}
              >
                V 1.9.7
              </div>
              <div
                style={{
                  display: "flex",

                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                {isUserThere && (
                  <Header style={{ position: "fixed", top: "0" }} />
                )}

                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                  }}
                >
                  {" "}
                  <Switch>
                    <Route path="/?" component={Homepage}>
                      {isUserThere ? <Homepage /> : <Loginpage />}
                    </Route>
                    <Route exact path="/" component={Homepage}>
                      {isUserThere ? <Homepage /> : <Loginpage />}
                    </Route>

                    <Route
                      exact
                      path="/RequestReset"
                      component={RequestResetPasswordPage}
                    />
                    <Route exact path="/Register" component={RegisterPage} />

                    <Route exact path="/create-ticket">
                      {isUserThere ? <EditTicket /> : <Loginpage />}
                    </Route>

                    <Route path="/edit-ticket">
                      {isUserThere ? <EditTicket /> : <Loginpage />}
                    </Route>

                    <Route path="/Uploader">
                      {isUserThere ? <Uploader /> : <Loginpage />}
                    </Route>
                    <Route path="/field-ticket-viewer">
                      {isUserThere ? <FieldTicketViewer /> : <Loginpage />}
                    </Route>
                    <Route
                      path="/ResetPassword"
                      component={ResetPasswordPage}
                    />

                    <Route
                      exact
                      path="/salesperson-groups"
                      component={isUserThere ? SalesPersonGroups : Loginpage}
                    />
                  </Switch>
                </div>
              </div>
            </Router>
          </ConfigProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(App);
