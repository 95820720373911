import { Result } from "antd";

const CompletedScreen = () => {
  return (
    <div className="animate__animated animate__bounceIn">
      <Result
        status="success"
        title="Shipment Complete"
        subTitle="No further action is required."
      />
    </div>
  );
};

export default CompletedScreen;
