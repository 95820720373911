import customAxios from "../../config/axiosConfig";
import { DEV_MODE } from "../../config/ipaddress";

export const getUploadedFiles = async (record) => {
  try {
    const response = await customAxios.post(`/versions`, {
      folderName: `${DEV_MODE ? "TEST-" : ""}${record.businessUnit}`,
      fileName: `${DEV_MODE ? "TEST-" : ""}${record.ticketNumber}`,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(data);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received: ${error.request}`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};
