import { Avatar, Popover } from "antd";
import "./UserCard.styles.css";
import { UserOutlined } from "@ant-design/icons";

const UserCard = ({ name, content }) => {
  return (
    <Popover content={content}>
      {" "}
      <div className="user__card__container">
        <Avatar
          shape="square"
          icon={
            <UserOutlined
              style={{
                color: "black",
              }}
            />
          }
          style={{
            backgroundColor: "white",
          }}
          size="small"
        />{" "}
        <p className="user__card__label">{name}</p>
      </div>
    </Popover>
  );
};

export default UserCard;
