import React, { useState } from "react";
import { Input, Form, Modal, Radio, Checkbox } from "antd";

import { CloseOutlined } from "@ant-design/icons";
import customAxios from "../../config/axiosConfig";

import { withRouter } from "react-router-dom";
import "./LoginPage.component.style.css";
import { store } from "../../redux2/store";
import { CONNECT_WITH_SSO } from "../../redux2/types";
import Button from "../../components/button/Button";
import backgroundImage from "../../assets/login_bg.png";
import logo from "../../assets/steelhausCA_logo1.png";

import { toast } from "react-toastify";

function UpdatePassword(history) {
  history.push("/RequestReset");
}

function register(history) {
  history.push("/Register");
}

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "It is not a valid email",
  },
};
const LoginPage = withRouter(({ history }) => {
  const [form] = Form.useForm();
  const [modalvisible, setmodalvisible] = useState(false);
  const [roles, setroles] = useState([]);
  const [selectedRole, setselectedRole] = useState("");
  const [email, setemail] = useState("");
  const [pwd, setpwd] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // Added state for Remember Me checkbox

  const onReset = () => {
    form.resetFields();
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const loginFunc = async (email, password, role) => {
    await customAxios
      .post(`/login`, {
        email: email,
        password: password,
        role: role,
      })
      .then((result) => {
        if (
          result.data === "Account not valid" ||
          result.data === "Wrong Email address or Password"
        ) {
          toast.error(
            "The provided email or password does not match our records.",
            {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            }
          );
        } else if (result.data === "Account just Disabled") {
          toast.error(
            "You've exceeded the allowed number of login attempts. Your account will be temporarily disabled for the next 24 hours.",
            {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: false,
            }
          );
        } else if (result.data === "Account Disabled") {
          toast.error(
            "Your account has been disabled. Please reach out to our support team for assistance in restoring access to your account.",
            {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: false,
            }
          );
        } else {
          const today = new Date();
          const expirydate = new Date(
            new Date(today).setHours(today.getHours() + 8760)
          );
          // var expirydate=new Date(new Date(today).setMinutes (today.getMinutes() + 3));
          localStorage.setItem("username", result.data.fullName);
          localStorage.setItem("user", expirydate);
          store.dispatch({
            type: CONNECT_WITH_SSO,
            user: result.data,
          });
        }
      });
  };

  const handleOK = () => {
    loginFunc(email, pwd, selectedRole);
    setmodalvisible(false);
  };

  const onFinish = async (values, history) => {
    let isMultiple = false;
    //check weather if multiple role
    await customAxios
      .post(`/checkmultiroles`, {
        email: values.emailladress,
      })
      .then((result) => {
        // ask user to select a role
        if (result.data.isMultiple === true) {
          isMultiple = true;
          setemail(values.emailladress);
          setpwd(values.password);
          setroles(result.data.roles);
          setmodalvisible(true);
        }
      });

    if (isMultiple) {
      return;
    } else {
      loginFunc(values.emailladress, values.password, "");
    }
  };
  return (
    <>
      <div className="modalstyle">
        <Modal
          title="Please Select a Role You like to log in"
          width={800}
          visible={modalvisible}
          onOk={handleOK}
          onCancel={() => {
            setmodalvisible(false);
          }}
        >
          <div>
            <Radio.Group
              onChange={(e) => {
                setselectedRole(e.target.value);
              }}
              value={selectedRole}
            >
              {roles.map((rl, index) => {
                return (
                  <Radio key={index} value={rl}>
                    {rl}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
        </Modal>
      </div>

      <div style={{ width: "100%", height: "100%", display: "flex" }}>
        <div
          style={{
            width: "50%",
            height: "100%",
            display: "flex",

            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <div
            style={{ display: "flex", justifyContent: "left", width: "100%" }}
          >
            <img src={logo}></img>
          </div>
          <div
            className="animate__animated animate__fadeIn"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              gap: "20px",
              height: "90%",
              justifyContent: "center",
            }}
          >
            {" "}
            <div>
              {" "}
              <h1
                style={{
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Welcome Back!
              </h1>
            </div>
            <Form
              name="basic"
              form={form}
              validateMessages={validateMessages}
              labelCol={{ span: 5 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              requiredMark={false}
              // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                hasFeedback
                labelCol={{ span: 24 }}
                label="Email Address"
                name="emailladress"
                rules={[{ required: true, type: "email" }]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 24 }}
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>
              <Form.Item>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                  >
                    Remember me
                  </Checkbox>
                  <a
                    style={{ color: "#3498db", cursor: "pointer" }}
                    className="login-form-forgot"
                    onClick={() => UpdatePassword(history)}
                  >
                    Forgot password?
                  </a>
                </div>
              </Form.Item>
              <Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <p style={{ color: "black" }}>
                Not registered Yet?{" "}
                <span
                  style={{ color: "#3498db", cursor: "pointer" }}
                  onClick={() => register(history)}
                >
                  Create an Account
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </>
  );
});

export default LoginPage;
