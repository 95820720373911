import customAxios from "../../config/axiosConfig";

export const fetchNextTicketNumber = async () => {
  try {
    const response = await customAxios.get(`/ticket/form/nextTicket`);
    return response.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const fetchTicketFormData = async () => {
  try {
    const response = await customAxios.get(`/ticket/form/data`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const fetchTicketProducts = async (location) => {
  try {
    const response = await customAxios.get(`/ticket/form/products`, {
      params: { location },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const updateTicketInformation = async (data) => {
  try {
    const response = await customAxios.post(`/ticket/update`, {
      data,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const submitTicket = async (data) => {
  try {
    const response = await customAxios.post(`/ticket/submit`, {
      data,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};
