import React, { useState, useEffect } from "react";
import { Select, Empty, Spin } from "antd";
import Button from "../../components/button/Button";
import { useHistory, useLocation } from "react-router-dom";

import customAxios from "../../config/axiosConfig";
import ipaddress from "../../config/ipaddress";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "react-redux";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import "./fieldTicketViewer.styles.css";
import HeaderComponent from "../../components/header/header.component";
import FooterComponent from "../../components/footer/footer.component";
import ShipModal from "../../components/ship-modal/ship-modal.component";
import { toast } from "react-toastify";

const { Option } = Select;

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FieldTicketViewer = ({ user }) => {
  const location = useLocation();
  const history = useHistory();

  const [numPages, setNumPages] = useState(null);
  const [op, setOp] = useState(location.state.buff);
  const [currentPdf, setCurrentPdf] = useState(op[0].buff);
  const [pdfError, setPdfError] = useState(null);
  const [shipModalVisible, setShipModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfError(null);
  };

  const onDocumentLoadError = (error) => {
    setPdfError(error);
  };
  const onChange = (value) => {
    const selectedPdf = op.find((item) => item.index === value);
    if (selectedPdf) {
      setCurrentPdf(selectedPdf.buff);
    }
  };

  const isFieldManager = user.role.trim() === "FM";
  const isSalesperson = user.role.trim() === "SP";
  const isSuperAdmin = user.role.trim() === "superadmin";
  const isViewer = user.role.trim() === "viewer";

  const showApprove = () => {
    const { status } = location.state.navdata;
    if (
      (status === "pending" && (isFieldManager || isSuperAdmin)) ||
      (status === "Field Manager Approved" && (isSalesperson || isSuperAdmin))
    ) {
      return true;
    }
    return false;
  };

  const showApproveAndShip = () => {
    const { status } = location.state.navdata;
    if (
      status === "Field Manager Approved" &&
      (isSalesperson || isSuperAdmin)
    ) {
      return true;
    }
    return false;
  };

  const showRevert = () => {
    const { status } = location.state.navdata;
    if (status !== "pending") {
      return true;
    } else {
      return false;
    }
  };

  const handleRevert = async () => {
    setLoading(true);
    try {
      await customAxios.patch(`/navstatuschange`, {
        ticketNumber: location.state.navdata.ticketNumber,
        Field_Ticket_Status: "1",
      });

      await customAxios.post(`/editstatus`, {
        ticketNumber: location.state.navdata.ticketNumber,
        status: "pending",
        approvedsalesperson: user.fullName,
      });

      toast.success("Ticket status has been changed to pending.", {
        theme: "dark",
        position: "bottom-right",
        closeOnClick: "true",
        autoClose: 5000,
      });

      setLoading(false);
    } catch (error) {
      toast.error("Could not revert to pending. Please try again later.", {
        theme: "dark",
        position: "bottom-right",
        closeOnClick: "true",
        autoClose: 5000,
      });

      setLoading(false);
    }
  };

  const handleApproveAndShip = async () => {
    //Execute approve function
    await handleApprove(false);

    //Execute shipping logic
    setShipModalVisible(true);
  };

  const handleApprove = async (showApprovalNotification = true) => {
    setLoading(true);
    if (location.state.navdata.status === "pending") {
      try {
        const response = await customAxios
          .patch(`/navstatuschange`, {
            ticketNumber: location.state.navdata.ticketNumber,
            Field_Ticket_Status: "2",
          })
          .catch((error) => {
            if (error.response) {
              // Server responded with an error status code
              const { status, data } = error.response;
              throw new Error(`${data}`);
            } else if (error.request) {
              // Request made, no response
              throw new Error(`No response received`);
            } else {
              // Error setting up or performing the request
              throw new Error(`Error: ${error.message}`);
            }
          });

        if (response.data) {
          await customAxios
            .post(`/editstatus`, {
              ticketNumber: location.state.navdata.ticketNumber,
              status: "Field Manager Approved",
              approvedsalesperson: user.fullName,
              customerName: location.state.navdata.customerName,
              branch: location.state.navdata.branch,
              businessUnit: location.state.navdata.businessUnit,
              postDate: location.state.navdata.postDate,
              uwi: location.state.navdata.uwi,
              dueDate: location.state.navdata.dueDate,
              description: location.state.navdata.comment,
              customerRep: location.state.navdata.customerRep,
              selectcustomer_No: location.state.navdata.customerNo,
              steelhausRep: location.state.navdata.steelhausRep,
              operation: location.state.navdata.operation,
              externalDocumentNo: location.state.navdata.externalDocumentNo,
              street: "",
              city: "",
              province: "",
            })
            .catch((error) => {
              if (error.response) {
                // Server responded with an error status code
                const { status, data } = error.response;
                throw new Error(`${data}`);
              } else if (error.request) {
                // Request made, no response
                throw new Error(`No response received`);
              } else {
                // Error setting up or performing the request
                throw new Error(`Error: ${error.message}`);
              }
            });

          showApprovalNotification &&
            toast.success(
              "Ticket status updated successfully in Business Central.",
              {
                theme: "dark",
                position: "bottom-right",
                closeOnClick: "true",
                autoClose: 5000,
              }
            );

          setLoading(false);
        } else {
          toast.error(
            "Could not change ticket status in Business Central. Please try again later.",
            {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            }
          );

          setLoading(false);
        }
      } catch (error) {
        toast.error(error.message, {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });

        setLoading(false);
      }
    } else if (
      location.state.navdata.status === "Field Manager Approved" &&
      location.state.navdata.discount < 10
    ) {
      try {
        const response = await customAxios
          .patch(`/navstatuschange`, {
            ticketNumber: location.state.navdata.ticketNumber,
            Field_Ticket_Status: "3",
          })
          .catch((error) => {
            if (error.response) {
              // Server responded with an error status code
              const { status, data } = error.response;
              throw new Error(`${data}`);
            } else if (error.request) {
              // Request made, no response
              throw new Error(`No response received`);
            } else {
              // Error setting up or performing the request
              throw new Error(`Error: ${error.message}`);
            }
          });

        if (response.data) {
          showApprovalNotification &&
            toast.success(
              "Ticket status updated successfully in Business Central.",
              {
                theme: "dark",
                position: "bottom-right",
                closeOnClick: "true",
                autoClose: 5000,
              }
            );

          setLoading(false);

          await customAxios
            .post(`/editstatus`, {
              ticketNumber: location.state.navdata.ticketNumber,
              status: "Approved",
              approvedsalesperson: user.fullName,
              customerName: location.state.navdata.customerName,
              branch: location.state.navdata.branch,
              businessUnit: location.state.navdata.businessUnit,
              postDate: location.state.navdata.postDate,
              uwi: location.state.navdata.uwi,
              dueDate: location.state.navdata.dueDate,
              description: location.state.navdata.comment,
              customerRep: location.state.navdata.customerRep,
              selectcustomer_No: location.state.navdata.customerNo,
              steelhausRep: location.state.navdata.steelhausRep,
              operation: location.state.navdata.operation,
              externalDocumentNo: location.state.navdata.externalDocumentNo,
              street: "",
              city: "",
              province: "",
            })
            .catch((error) => {
              if (error.response) {
                // Server responded with an error status code
                const { status, data } = error.response;
                throw new Error(`${data}`);
              } else if (error.request) {
                // Request made, no response
                throw new Error(`No response received`);
              } else {
                // Error setting up or performing the request
                throw new Error(`Error: ${error.message}`);
              }
            });
        } else {
          toast.error(
            "Could not change ticket status in Business Central. Please try again later.",
            {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            }
          );

          setLoading(false);
        }
      } catch (error) {
        toast.error(error.message, {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });
        setLoading(false);
      }
    } else if (
      location.state.navdata.status === "Field Manager Approved" &&
      location.state.navdata.discount >= 10
    ) {
      try {
        const response = await customAxios
          .patch(`/navstatuschange`, {
            ticketNumber: location.state.navdata.ticketNumber,
            Field_Ticket_Status: "2",
          })
          .catch((error) => {
            if (error.response) {
              // Server responded with an error status code
              const { status, data } = error.response;
              throw new Error(`${data}`);
            } else if (error.request) {
              // Request made, no response
              throw new Error(`No response received`);
            } else {
              // Error setting up or performing the request
              throw new Error(`Error: ${error.message}`);
            }
          });

        if (response.data) {
          await customAxios
            .post(`/editstatus`, {
              ticketNumber: location.state.navdata.ticketNumber,
              status: "Sales Manager Approval Required",
              approvedsalesperson: user.fullName,
            })
            .catch((error) => {
              if (error.response) {
                // Server responded with an error status code
                const { status, data } = error.response;
                throw new Error(`${data}`);
              } else if (error.request) {
                // Request made, no response
                throw new Error(`No response received`);
              } else {
                // Error setting up or performing the request
                throw new Error(`Error: ${error.message}`);
              }
            });

          toast.error(
            "Field manager approval required. Please contact a field manager to review the discount request.",
            {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            }
          );

          setLoading(false);
        } else {
          toast.error(
            "Could not change ticket status in Business Central. Please try again later.",
            {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            }
          );

          setLoading(false);
        }
      } catch (error) {
        toast.error(error.message, {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });

        setLoading(false);
      }
    }
  };

  return (
    <>
      <Spin spinning={loading} fullscreen={true}></Spin>
      <ShipModal
        visible={shipModalVisible}
        setVisible={setShipModalVisible}
        selectedRowData={{
          ticketNumber: location.state.navdata.ticketNumber,
        }}
        onSuccess={() => {
          history.push("./");
        }}
      />

      <div className="field__ticket__viewer__container animate__animated animate__fadeIn ">
        <div className="field__ticket__viewer__content">
          <div className="field__ticket__viewer__controls">
            <div className="field__ticket__viewer__buttons">
              <Button
                onClick={() => {
                  history.push("./");
                }}
              >
                Back
              </Button>
              {isViewer ? null : (
                <>
                  {showRevert() && (
                    <Button
                      onClick={async () => {
                        await handleRevert();
                        history.push("./");
                      }}
                    >
                      Revert To Last Status
                    </Button>
                  )}
                  {showApprove() && (
                    <Button
                      onClick={async () => {
                        await handleApprove();
                        history.push("./");
                      }}
                    >
                      Approve
                    </Button>
                  )}
                  {showApproveAndShip() && (
                    <Button onClick={handleApproveAndShip}>
                      Approve & Ship
                    </Button>
                  )}
                </>
              )}
            </div>

            <Select
              style={{ width: "50%" }}
              showSearch
              placeholder="Select a Revision"
              optionFilterProp="children"
              onChange={onChange}
              defaultValue={op[0].index}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {op.map((i, index) => (
                <Option value={i.index} key={i.index}>
                  {i.index === 0 ? "Latest File" : `Revision ${i.index}`}
                </Option>
              ))}
            </Select>
          </div>

          <div className="field__ticket__viewer__pdf__container">
            {pdfError ? (
              <Empty description="Failed to load the PDF" />
            ) : (
              <Document
                file={`data:application/pdf;base64, ${encodeURI(currentPdf)}`}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page size={"A4"} scale={2} pageNumber={page} key={page} />
                  ))}
              </Document>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(FieldTicketViewer);
