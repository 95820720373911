import { Result } from "antd";
import Button from "../button/Button";
import "./ServerErrorResult.styles.css";

const ServerErrorResult = ({ subTitle = "", showReload = false }) => {
  return (
    <Result
      className="animate__animated animate__fadeIn"
      status="500"
      title="Something went wrong"
      subTitle={subTitle}
      extra={
        <div className="server__error__result__extra">
          {showReload && (
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload App
            </Button>
          )}
        </div>
      }
    />
  );
};

export default ServerErrorResult;
