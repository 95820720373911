import { React, useEffect, useState, salespersonef } from "react";

import customAxios from "../../config/axiosConfig";
import { Form, Modal, Select } from "antd";

import { Tag } from "antd";

import { Spin } from "antd";
import { Layout } from "antd";
import { toast } from "react-toastify";

import ipaddress from "../../config/ipaddress";
var randomColor = require("randomcolor"); // import the script
const Groupmodal = ({ group, visible, setvisible, setgroupvisible }) => {
  const [oldcustomer, setoldcustomer] = useState(group.customer_name);

  const { Option } = Select;
  const [groupname, setgroupname] = useState(group.groupname);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectcust, setselectcust] = useState(group.customer_name);
  const [salespersons, setsalespersons] = useState();
  const [form] = Form.useForm();
  const [customer, setcustomer] = useState([]);
  const [selectedsalesperson, setselectedsalesperson] = useState(
    group.salespersons.split(",") != "undefined"
      ? group.salespersons.split(",")
      : ""
  );
  const [loadcust, setloadcust] = useState(true);

  const handleOk1 = () => {
    let data = {
      groupid: group.groupid,
      // groupname: groupname,
      customer_name: selectcust,
      salespersons: selectedsalesperson,
      //oldcustomer: oldcustomer,
    };
    customAxios
      .post(ipaddress + `/updategroup`, {
        data,
      })
      .then((response) => {
        setgroupvisible(false);
        setvisible(false);
        setConfirmLoading(false);
        setgroupname("");
        setselectcust("");
        setselectedsalesperson("");
        setselectcust("");
      })
      .catch((error) => {
        toast.error(
          "Could not update salesperson group. Please try again later.",
          {
            toastId: "salespeopleError",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
          }
        );
      });
  };
  const handleCancel = () => {
    setgroupvisible(false);
    setvisible(false);
  };
  function onChangesalesperson(value) {
    setselectedsalesperson(value);
  }
  function onChangecustomer(value, e) {
    setselectcust(value);
  }
  function onChangeGroupName(value, e) {
    setgroupname(value.target.value);
  }
  useEffect(() => {
    var color = randomColor();
    customAxios.get(ipaddress + `/getspusers`).then((response) => {
      setsalespersons(response.data);
      setloadcust(false);
    });

    customAxios
      .get(ipaddress + `/customerlist`)
      .then((res) => {
        const formreview_data = res.data;

        setloadcust(false);

        setcustomer(formreview_data);
      })
      .catch((error) => {
        setloadcust(false);
      });
  }, []);
  return (
    <>
      <Modal
        width={800}
        visible={visible}
        onOk={handleOk1}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <br></br>
        <div>
          {" "}
          <label>Customer Name: </label>
          <Select
            style={{
              width: "100%",
              textAlign: "left",
            }}
            className="customer3"
            placeholder="Select a Customer"
            optionFilterProp="children"
            onChange={onChangecustomer}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectcust}
          >
            {!loadcust ? (
              <>
                {customer.map((item) => {
                  return (
                    <>
                      {item.Name.length > 1 ? (
                        <Option value={item.Name} key={item.No}>
                          {item.Name}
                        </Option>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}{" "}
              </>
            ) : (
              <p style={{ color: "blue" }}>
                {" "}
                <Spin fullscreen={true} />
              </p>
            )}
          </Select>
        </div>{" "}
        <br></br>
        <div>
          {" "}
          <label>Selected Sales Persons: </label>
          <Select
            label="loaind"
            mode="multiple"
            style={{
              width: "100%",
              textAlign: "left",
            }}
            className="customer3"
            placeholder="Select a salesperson"
            optionFilterProp="children"
            onChange={onChangesalesperson}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedsalesperson}
          >
            {!loadcust ? (
              <>
                {salespersons?.map((item) => {
                  return (
                    <>
                      <Option value={item.emailAddress}>{item.fullName}</Option>
                    </>
                  );
                })}{" "}
              </>
            ) : (
              <p style={{ color: "blue" }}>
                {" "}
                <Spin fullscreen={true} />
              </p>
            )}
          </Select>
        </div>{" "}
      </Modal>
    </>
  );
};

export default Groupmodal;
