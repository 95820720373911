import { useEffect, useState } from "react";
import { Input, Form, Select, Space } from "antd";
import Button from "../../components/button/Button";

import customAxios from "../../config/axiosConfig";

import { withRouter } from "react-router-dom";
import "./register.component.style.css";
import backgroundImage from "../../assets/login_bg.png";
import logo from "../../assets/steelhausCA_logo1.png";
import { toast } from "react-toastify";

const { Option } = Select;

const width = "'" + window.innerWidth + "px'";
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "It is not a valid email!",
  },
};

const RegisterPage = withRouter(({ history }) => {
  const [branches, setbranches] = useState([]);
  const [inviteCodes, setinviteCodes] = useState({});
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };

  const fetchData = async () => {
    try {
      const branchesResponse = await customAxios.get(`/getallbranches`);
      setbranches(branchesResponse.data);

      const inviteCodesResponse = await customAxios.get(`/getallInviteCodes`);
      setinviteCodes(inviteCodesResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors here if needed
    }
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function here
  }, []); // Empty dependency array means this effect runs once after the initial render

  const onFinish = (values) => {
    let hasBranch = false;

    hasBranch =
      inviteCodes.invitation_fieldmanager === values.invitationcode ||
      inviteCodes.invitation_salesperson === values.invitationcode
        ? true
        : false;

    const id = toast.loading("Please wait...", {
      position: "bottom-right",
      theme: "dark",
    });

    customAxios
      .post(`/registernewuser`, {
        username: values.username,
        email: values.email,
        password: values.password,
        invitationcode: values.invitationcode,
        company: "Steelhaus Canada Employee", // Set it to the default value
        branch: hasBranch ? values.branch : null,
      })
      .then((result) => {
        if (result.data === "Invaild Invitation Code") {
          toast.update(id, {
            render: "The provided invitation code is invalid.",
            type: "error",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });
        } else if (result.data === "Email is already registered") {
          toast.update(id, {
            render: "An account with this email address already exists.",
            type: "error",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });
        } else {
          toast.update(id, {
            render: "Registration successful!",
            type: "success",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });

          history.push("/");
        }
      });
  };

  const onChangebranch = (value) => {};

  return (
    <>
      <div style={{ width: "100%", height: "100%", display: "flex" }}>
        <div
          style={{
            width: "50%",
            height: "100%",
            display: "flex",

            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <div
            style={{ display: "flex", justifyContent: "left", width: "100%" }}
          >
            <img
              className="authentication__logo"
              src={logo}
              onClick={() => {
                history.push("/");
              }}
            ></img>
          </div>
          <div
            className="animate__animated animate__fadeIn"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              gap: "20px",
              height: "90%",
              justifyContent: "center",
            }}
          >
            {" "}
            <div>
              {" "}
              <h1
                style={{
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Create an Account
              </h1>
            </div>
            <Form
              name="basic"
              form={form}
              validateMessages={validateMessages}
              labelCol={{ span: 5 }}
              // wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              requiredMark={false}
              // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                labelCol={{ span: 24 }}
                hasFeedback
                label="Full Name"
                name="username"
                rules={[{ required: true }]}
              >
                <Input placeholder="Enter your full name" />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 24 }}
                hasFeedback
                label="Email"
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 24 }}
                tooltip="Please contact a supervisor for an invite code."
                hasFeedback
                label="Invitation Code "
                name="invitationcode"
                rules={[{ required: true }]}
              >
                <Input placeholder="Enter your invitation code" />
              </Form.Item>

              <Form.Item
                labelCol={{ span: 24 }}
                label="Branch"
                name="branch"
                hasFeedback
                rules={[{ required: true }]}
              >
                <Select
                  onChange={onChangebranch}
                  placeholder="Select your branch"
                >
                  {branches.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 24 }}
                label="Password"
                name="password"
                hasFeedback
                rules={[
                  { required: true },
                  {
                    min: 6,
                    message: "Password must be minimum 6 characters.",
                  },
                ]}
              >
                <Input.Password allowClear placeholder="Create a password" />
              </Form.Item>

              <Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Signup
                </Button>
              </Form.Item>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <p style={{ color: "black" }}>
                Already have an account?{" "}
                <span
                  style={{ color: "#3498db", cursor: "pointer" }}
                  onClick={() => history.push("/")}
                >
                  Log In
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </>
  );
});

export default RegisterPage;
