import { useState, useEffect } from "react";
import { fetchTicketProducts } from "../EditTicketPage.service";
import { Table, Input, Checkbox } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "../../../components/button/Button";
import EditableCell from "../../../components/editable-cell/EditableCell.component";
import CommentMaker from "../../../components/comment-maker/CommentMaker.component";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchNextTicketNumber } from "../EditTicketPage.service";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import "./ProductSelection.styles.css";
import ServerErrorResult from "../../../components/server-error-result/ServerErrorResult.component";

const ProductSelection = ({
  form,
  user,
  mode,
  lastLineNumber,
  setLastLineNumber,
}) => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [showInStockOnly, setShowInStockOnly] = useState(false); // State for in-stock checkbox

  const ticketNumberQuery = useQuery({
    queryKey: ["nextTicketNumber"],
    queryFn: fetchNextTicketNumber,
    enabled: mode === "create" && !form.getFieldValue("ticketNumber"),
  });

  const productsQuery = useQuery({
    queryKey: ["products"],
    queryFn: async () => {
      const result = await fetchTicketProducts(form.getFieldValue("branch"));

      //Assign keys to all products for React to render properly
      const resultWithKeys = result.map((product, index) => ({
        ...product,
        key: uuidv4(),
      }));

      setProducts(setNegativeInventoriesToZero(resultWithKeys));
      return resultWithKeys;
    },
  });

  const isLoading = productsQuery.isPending;

  const isPaused = productsQuery.isPaused;

  const isError = productsQuery.isError;

  const isFetching = productsQuery.isFetching;

  if (isPaused) {
    toast.error(
      "It seems that your network connection is currently unavailable. We'll automatically attempt to fetch the products again once the connection is reestablished.",
      {
        toastId: "networkError",
        position: "bottom-right",
        theme: "dark",
        autoClose: 10000,
      }
    );
  }

  useEffect(() => {
    // fetchProducts();

    if (form.getFieldValue("selectedProducts")) {
      setSelectedProducts(form.getFieldValue("selectedProducts"));
    }

    if (mode === "create" && !ticketNumberQuery.isPending) {
      // Check if ticketNumberQuery.data is available
      if (
        ticketNumberQuery.data !== undefined &&
        ticketNumberQuery.data !== null
      ) {
        // Generate and set ticket number
        setTicketNumber(
          form.getFieldValue("branch"),
          form.getFieldValue("businessUnit"),
          ticketNumberQuery.data
        );
        // Set Steelhaus rep
        form.setFieldValue("steelhausRep", user.fullName || "");
      }
    }
  }, [ticketNumberQuery.data]);

  useEffect(() => {
    form.setFieldValue("selectedProducts", selectedProducts);
  }, [selectedProducts]);

  const inventoryColumns = [
    {
      title: "Part Number",
      dataIndex: "No",
      key: "No",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Business Central Inventory",
      dataIndex: "Inventory",
      key: "Inventory",
    },
  ];
  const selectedProductsColumns = [
    {
      title: "Part Number",
      dataIndex: "No",
      key: "No",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text, record) => (
        <EditableCell
          value={text}
          onChange={(value) => handleDescriptionChange(record, value)}
          type="textArea"
          showCount={true}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "Inventory",
      key: "Inventory",
      render: (text, record) => (
        <EditableCell
          value={text}
          onChange={(value) => handleQuantityChange(record, value)}
          type="input"
        />
      ),
    },
    {
      title: "Business Central Inventory",
      dataIndex: "BC_Inventory",
      key: "BC_Inventory",
      render: (text, record) => {
        const product = products.find((p) => p.No === record.No);
        return product ? product.Inventory : text;
      },
    },
    {
      title: "Price",
      dataIndex: "Unit_Cost",
      key: "Unit_Cost",
      render: (text, record) => (
        <div className="product__selection__price__input">
          <span style={{ color: "#3498db" }}>$</span>
          <EditableCell
            value={text}
            onChange={(value) => handlePriceChange(record, value)}
            type="input"
          />
        </div>
      ),
    },
    {
      title: "UOM",
      dataIndex: "UOM",
      key: "UOM",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "5%",
      render: (text, record) => (
        <Button
          type="danger"
          icon={<DeleteOutlined />}
          onClick={() => removeSelectedProduct(record)}
        />
      ),
    },
  ];

  const setNegativeInventoriesToZero = (products) => {
    let productsClone = JSON.parse(JSON.stringify(products));
    for (var i = 0; i < products.length; i++) {
      if (productsClone[i].Inventory < 0) {
        productsClone[i].Inventory = 0;
      }
    }

    return productsClone;
  };

  const addToSelectedProducts = (record) => {
    //Perform stock check if product is of type "Inventory"
    if (record.Type === "Inventory" && record.Inventory <= 0) {
      toast.error(
        "This product is currently out of stock. Please try again later.",
        {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        }
      );

      return;
    }

    let recordCopy = {
      Description: record.Description,
      Inventory: 0,
      Unit_Cost: "0.00",
      key: uuidv4(),
      LineNumber: lastLineNumber + 1000,
      No: record.No,
      UOM: record.Base_Unit_of_Measure,
      ticketNumber: form.getFieldValue("ticketNumber"),
      type: "product",
    };

    setLastLineNumber(recordCopy.LineNumber);

    setSelectedProducts([...selectedProducts, recordCopy]);
  };

  const removeSelectedProduct = (record) => {
    const updatedSelectedProducts = selectedProducts.filter(
      (item) => item.key !== record.key
    );
    setSelectedProducts(updatedSelectedProducts);
  };

  const handleQuantityChange = (record, value) => {
    // Find the corresponding product
    const product = products.find((p) => p.No === record.No);

    //Check if there is sufficient inventory in Business Central
    //This check is bypassed if the product is not of type "Inventory"
    if (
      product.Type === "Inventory" &&
      Number(value) > Number(product.Inventory)
    ) {
      // Display an error message and do not update the quantity
      toast.error("Quantity cannot exceed Business Central Inventory.", {
        theme: "dark",
        position: "bottom-right",
        closeOnClick: "true",
        autoClose: 5000,
      });
    } else {
      // Update the quantity for the specific product record
      const updatedSelectedProducts = selectedProducts.map((item) => {
        if (item.key === record.key) {
          return { ...item, Inventory: value };
        }
        return item;
      });
      setSelectedProducts(updatedSelectedProducts);
    }
  };

  const handlePriceChange = (record, value) => {
    // Update the price for the specific product record
    const updatedSelectedProducts = selectedProducts.map((item) => {
      if (item.key === record.key) {
        return { ...item, Unit_Cost: value };
      }
      return item;
    });
    setSelectedProducts(updatedSelectedProducts);
  };

  const handleDescriptionChange = (record, value) => {
    // Update the description for the specific product record
    const updatedSelectedProducts = selectedProducts.map((item) => {
      if (item.key === record.key) {
        return { ...item, Description: value };
      }
      return item;
    });
    setSelectedProducts(updatedSelectedProducts);
  };

  const setTicketNumber = (branch, businessUnit, numberOfTickets) => {
    let ticketNumber = `${branch}${numberOfTickets}`;

    form.setFieldValue("ticketNumber", ticketNumber);

    return ticketNumber;
  };

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const filterProducts = () => {
    const filteredProducts = products.filter((product) => {
      const isDescriptionMatch =
        product.Description.toLowerCase().includes(searchText);
      const isPartNumberMatch = product.No.toLowerCase().includes(searchText);
      const isInStock = product.Inventory > 0;

      if (showInStockOnly) {
        return isInStock && (isDescriptionMatch || isPartNumberMatch);
      } else {
        return isDescriptionMatch || isPartNumberMatch;
      }
    });

    return filteredProducts;
  };

  return (
    <div className="product__selection__container">
      {isError ? (
        <ServerErrorResult subTitle="We couldn't retrieve the products from Business Central at the moment. Please check your internet connection and try again. If the issue persists, contact support for assistance." />
      ) : (
        <>
          <div className="product__selection__products">
            {" "}
            {!isLoading && !isFetching ? (
              <div className="product__selection__in__stock">
                <span className="product__selection__table__title">
                  Business Central Products
                </span>
                <Checkbox
                  checked={showInStockOnly}
                  onChange={(e) => setShowInStockOnly(e.target.checked)}
                >
                  Show In Stock Only
                </Checkbox>
              </div>
            ) : (
              <></>
            )}
            <div className="product__selection__actions">
              <Input
                className="product__selection__search"
                addonBefore={<SearchOutlined />}
                onChange={(e) => handleSearch(e.target.value)}
                disabled={isLoading || isFetching}
              />
            </div>
            <Table
              loading={isLoading || isFetching}
              className="product__selection__inventory"
              columns={inventoryColumns}
              dataSource={filterProducts()}
              rowClassName={(record) =>
                selectedProducts.some(
                  (selectedProduct) => selectedProduct.No === record.No
                )
                  ? "grayed__out__row"
                  : ""
              }
              onRow={(record, rowIndex) => {
                const isProductSelected = selectedProducts.some(
                  (selectedProduct) => selectedProduct.No === record.No
                );

                return {
                  onClick: (event) => {
                    addToSelectedProducts(record);
                  },
                };
              }}
              search={{
                placeholder: "Search for products",
              }}
            />
          </div>

          {!isLoading && !isFetching ? (
            <>
              <div className="product__selection__selected">
                <span className="product__selection__table__title">
                  Selected Products
                </span>
                <Table
                  columns={selectedProductsColumns}
                  dataSource={selectedProducts}
                  pagination={false}
                />
              </div>

              <CommentMaker
                initialValues={form.getFieldValue("comments")}
                onChange={(comments) => {
                  form.setFieldValue("comments", comments);
                }}
                ticketNumber={form.getFieldValue("ticketNumber")}
                lastLineNumber={lastLineNumber}
                setLastLineNumber={setLastLineNumber}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProductSelection);
