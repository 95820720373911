import React, { useState, useEffect } from "react";
import logo from "../../assets/steelhausCA_logo.png";
import { connect } from "react-redux";
import "./header.styles.css";
import { withRouter } from "react-router-dom";
import customAxios from "../../config/axiosConfig";
import { store } from "../../redux2/store";
import { DISCONNECT, UPDATE_REVENUES } from "../../redux2/types";
import Burger from "../burger/Burger";
import styled from "styled-components";
import StatusBadge from "../status-badge/StatusBadge.component";
import { consolidateTicketInformation } from "../../pages/home-page/homepage.service";
import OnlineIndicator from "../online-indicator/OnlineIndicator.component";
import UserCard from "../user-card/UserCard.component";
import { toast } from "react-toastify";
import { Drawer } from "antd";
import { useQuery } from "@tanstack/react-query";
import { Menu } from "react-feather";

async function disconnectWithSSO(history) {
  store.dispatch({
    type: DISCONNECT,
  });
  localStorage.removeItem("username");
  history.push("/");
}

const Header = withRouter(({ history, user, revenue }) => {
  const [open, setOpen] = React.useState(false);
  const [ticketCounts, setTicketCounts] = useState(0);

  const revenueQuery = useQuery({
    queryKey: ["revenue"],
    queryFn: () => {
      return customAxios
        .post(`/RevenueTotals`, {
          user: user ? user.fullName : "",
          role: user ? `${user.role}` : "",
        })
        .then((res) => {
          const revenue_data = res.data;
          const map2 = new Map(Object.entries(revenue_data));
          setTicketCounts(map2);
          store.dispatch({
            type: UPDATE_REVENUES,
            revenue: res.data,
          });
          return res;
        });
    },
  });

  if (user && revenueQuery.isError) {
    toast.error("Unable to get revenue totals. Reload and try again.", {
      toastId: "networkError",
      position: "bottom-right",
      theme: "dark",
      autoClose: 10000,
    });
  }

  const onClose = () => {
    setOpen(false);
  };

  const node = React.useRef();

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  function onofflineClick(history) {
    history.push("/salesperson-groups");
  }

  function onHomeClick(history) {
    history.push("/");
    toast.dismiss();
  }

  function objectsToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr);
    return array
      .map((row) => {
        return Object.values(row)
          .map((value) => {
            return typeof value === "string" ? JSON.stringify(value) : value;
          })
          .toString();
      })
      .join("\n");
  }

  function exportToCsv(filename, rows) {
    var csvFile = objectsToCSV(rows);

    var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const consolidateTickets = async () => {
    let result = await consolidateTicketInformation();
    exportToCsv("consolidated-tickets.csv", result);
  };

  const userCardContent = (
    <div>
      <p style={{ margin: 0 }}>
        Role:{" "}
        {user?.role === "superadmin"
          ? "Admin"
          : user?.role === "NULL"
          ? "N/A"
          : user?.role}
      </p>
      <p style={{ margin: 0 }}>Branch: {user?.branch || "N/A"}</p>
    </div>
  );

  return (
    <>
      <Drawer onClose={onClose} open={open} closeIcon={false}>
        <div className="nav__links">
          <p
            className="sidebar__link"
            onClick={() => {
              onHomeClick(history);
              onClose();
            }}
          >
            <span role="img" aria-label="about us" />
            Home
          </p>{" "}
          <p
            className="sidebar__link"
            onClick={() => {
              consolidateTickets();
              onClose();
            }}
          >
            <span role="img" aria-label="about us" />
            Export Tickets
          </p>{" "}
          {user?.role == "superadmin" ? (
            <>
              <p
                className="sidebar__link"
                onClick={() => {
                  onofflineClick(history);
                  onClose();
                }}
              >
                <span role="img" aria-label="price" />
                Groups
              </p>
            </>
          ) : (
            <></>
          )}
          <p
            className="sidebar__link"
            onClick={() => {
              disconnectWithSSO(history);
              onClose();
            }}
          >
            <span role="img" aria-label="contact" />
            Sign out
          </p>
        </div>
      </Drawer>
      <div className="header__navbar">
        <div className="header__container__left">
          <img
            onClick={() => onHomeClick(history)}
            alt=""
            src={logo}
            style={{
              width: "40%",
              height: "50%",
              cursor: "pointer",
              padding: "5px",
            }}
          />
        </div>

        <div className="header__container__center">
          {" "}
          {user && !revenueQuery.isError && !revenueQuery.isPending ? (
            <>
              <div className="ticket__counts animate__animated animate__fadeIn">
                <StatusBadge
                  text={`${
                    ticketCounts
                      ? formatter.format(store.getState().revenue.pending)
                      : "$0.0"
                  }`}
                  type="pending"
                />

                <StatusBadge
                  text={`${
                    ticketCounts
                      ? formatter.format(store.getState().revenue.Approved)
                      : "$0.0"
                  }`}
                  type="approved"
                />
                <StatusBadge
                  text={`${
                    ticketCounts
                      ? formatter.format(
                          store.getState().revenue.fieldManagerApproved
                        )
                      : "$0.0"
                  }`}
                  type="field manager approved"
                />
                <StatusBadge
                  text={`${
                    ticketCounts
                      ? formatter.format(store.getState().revenue.total)
                      : "$0.0"
                  }`}
                  type="total"
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="header__container__right">
          {user && (
            <Menu
              size={32}
              className="header__menu"
              onClick={() => {
                setOpen(true);
              }}
            />
          )}

          {user && <UserCard name={user?.fullName} content={userCardContent} />}

          <OnlineIndicator />
        </div>
      </div>
    </>
  );
});

const mapStateToProps = (state) => ({
  user: state.user,
  revenue: state.revenue,
});
export default connect(mapStateToProps)(Header);
