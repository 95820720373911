import { useHistory, useLocation } from "react-router-dom";
import FooterComponent from "../footer/footer.component";

import { Layout, Spin } from "antd";

import customAxios from "../../config/axiosConfig";

import React, { useEffect, useRef, useState } from "react";

import { FileUpload } from "primereact/fileupload";

import { Tooltip } from "primereact/tooltip";

import ipaddress from "../../config/ipaddress";
import { DEV_MODE } from "../../config/ipaddress";

import "./uploader.styles.css";

import { toast } from "react-toastify";

const { Content } = Layout;

function Uploader() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className:
      "custom-choose-btn p-button-rounded p-button-outlined commandButton",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined commandButton",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined commandButton",
  };

  const location = useLocation();

  const emptyTemplate = () => {
    return (
      <div
        style={{ textAlign: "center" }}
        className="p-d-flex p-ai-center p-dir-col"
      >
        <i
          className="pi pi-file p-mt-3 p-p-5"
          style={{
            fontSize: "5em",
            borderRadius: "10px",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
            padding: "2rem",
          }}
        ></i>
      </div>
    );
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
      </div>
    );
  };
  const onTemplateUpload = (e) => {
    const uploadFile = e.files[0];
    setLoading(true);
    tobase64(uploadFile)
      .then((result) => {
        customAxios
          .post(`${ipaddress}/uploaddocument`, {
            buff: result,
            fileName: `${DEV_MODE === true ? "TEST-" : ""}${
              location.state.rowData.ticketNumber
            }`,
            folderName: `${DEV_MODE === true ? "TEST-" : ""}${
              location.state.rowData.businessUnit
            }`,
            ticketNumber: location.state.rowData.ticketNumber,
          })
          .then(async (res) => {
            setLoading(false);

            toast.success("File uploaded successfully.", {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            });

            history.push("./");
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Could not upload. Please try again later.", {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            });
          });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          "Could not convert file for uploading. Please try again later.",
          {
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
          }
        );
      });
  };

  const tobase64 = (file) =>
    new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <>
      <Spin spinning={loading} fullscreen={true} />{" "}
      <div>
        <div className="select">
          <Tooltip
            target=".custom-choose-btn"
            content="Browse Files"
            position="bottom"
          />
          <Tooltip
            target=".custom-upload-btn"
            content="Upload"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Clear"
            position="bottom"
          />

          <FileUpload
            id="fileupload"
            name="demo[]"
            url={`${ipaddress}/uploaddocument`}
            headerTemplate={headerTemplate}
            multiple
            customUpload
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
            emptyTemplate={emptyTemplate}
            // onUpload={onTemplateUpload}
            uploadHandler={onTemplateUpload}
            style={{ marginTop: "2rem", border: "1px solid grey" }}
          />
        </div>
      </div>
    </>
  );
}

export default Uploader;
