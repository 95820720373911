import React from "react";
import { Input, Form } from "antd";
import Button from "../../components/button/Button";

import customAxios from "../../config/axiosConfig";
import { withRouter } from "react-router-dom";
import "./RequestResetPassword.style.css";
import backgroundImage from "../../assets/login_bg.png";
import logo from "../../assets/steelhausCA_logo1.png";
import { ArrowLeft } from "react-feather";
import { toast } from "react-toastify";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "It is not a valid email!",
  },
};

const RequestResetPasswordPage = withRouter(({ history }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const id = toast.loading("Please wait...", {
      position: "bottom-right",
      theme: "dark",
    });
    customAxios
      .post(`/validateEmail`, {
        email: values.email,
      })
      .then((result) => {
        if (result.data === "Account not valid") {
          toast.update(id, {
            render: "Account not valid.",
            type: "error",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });
        } else if (result.data === "Account Disabled") {
          toast.update(id, {
            render: "Account disabled.",
            type: "error",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });
        } else {
          toast.update(id, {
            render:
              "Reset instructions have been dispatched to the provided email address.",
            type: "success",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });

          history.push("/");
        }
      });
  };
  return (
    <>
      <div style={{ width: "100%", height: "100%", display: "flex" }}>
        <div
          style={{
            width: "50%",
            height: "100%",
            display: "flex",

            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <div
            style={{ display: "flex", justifyContent: "left", width: "100%" }}
          >
            <img
              className="authentication__logo"
              src={logo}
              onClick={() => {
                history.push("/");
              }}
            ></img>
          </div>
          <div
            className="animate__animated animate__fadeIn"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              gap: "20px",
              height: "90%",
              justifyContent: "center",
            }}
          >
            {" "}
            <div>
              {" "}
              <h1
                style={{
                  textAlign: "left",
                  margin: 0,
                  marginBottom: "10px",
                }}
              >
                Forgot Password?
              </h1>
              <h5
                style={{
                  textAlign: "left",
                  margin: 0,
                  opacity: 0.6,
                }}
              >
                No worries, we'll send you reset instructions.
              </h5>
            </div>
            <Form
              name="basic"
              form={form}
              validateMessages={validateMessages}
              labelCol={{ span: 5 }}
              // wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              requiredMark={false}
              // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                labelCol={{ span: 24 }}
                hasFeedback
                label="Email"
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Reset password
                </Button>
              </Form.Item>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <p style={{ color: "black" }}>
                <div
                  style={{
                    color: "#3498db",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  onClick={() => history.push("/")}
                >
                  <ArrowLeft /> Back to Log In
                </div>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </>
  );
});

export default RequestResetPasswordPage;
