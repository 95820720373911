export function calculateTotal(arr) {
  let total = 0;
  for (let i = 0; i < arr.length; i++) {
    const inventory = arr[i].Inventory;
    const unitCost = arr[i].Unit_Cost;
    total += inventory * unitCost;
  }
  return total;
}

export function calculateDiscountAmount(total, discountPercentage) {
  if (discountPercentage === null) {
    return 0;
  }
  return (total * discountPercentage) / 100;
}

export function calculateSalesTax(cost, discountPercentage) {
  if (discountPercentage === null || isNaN(discountPercentage)) {
    discountPercentage = 0;
  }

  return (cost - calculateDiscountAmount(cost, discountPercentage)) * 0.05;
}

export function calculateTotalWithDiscount(arr, discountPercentage) {
  const subTotal = calculateTotal(arr);
  const discountAmount = calculateDiscountAmount(subTotal, discountPercentage);
  const salesTax = calculateSalesTax(subTotal, discountPercentage);
  const total = subTotal - discountAmount + salesTax;

  return total;
}
