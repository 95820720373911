import { Skeleton, Form, Row, Col, Select, Input, DatePicker } from "antd";

const TicketInformation = ({ loading, formData = {}, form }) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Customer"
            name="customerName"
            rules={[{ required: true, message: "Please select a customer" }]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                options={formData.customers}
                fieldNames={{ label: "Name", value: "Name" }}
                onSelect={(value) => {
                  const selectedOption = formData.customers.find(
                    (option) => option.Name === value
                  );

                  form.setFieldsValue({ customer: selectedOption });
                }}
                showSearch
                placeholder="Select a customer"
                filterOption={(inputValue, option) =>
                  option.Name.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0
                }
              />
            )}
          </Form.Item>
          <Form.Item
            label="Branch"
            name="branch"
            rules={[{ required: true, message: "Please select a branch" }]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                options={formData.branches}
                fieldNames={{ label: "code", value: "code" }}
                showSearch
                placeholder="Select a branch"
                filterOption={(inputValue, option) =>
                  option.code.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0
                }
              />
            )}
          </Form.Item>

          <Form.Item
            label="Business Unit"
            name="businessUnit"
            rules={[
              { required: true, message: "Please select a business unit" },
            ]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                options={formData.businessUnits}
                fieldNames={{ label: "name", value: "name" }}
                showSearch
                placeholder="Select a business unit"
                filterOption={(inputValue, option) =>
                  option.name.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0
                }
              />
            )}
          </Form.Item>

          <Form.Item
            label="Customer Representative"
            name="customerRep"
            rules={[
              {
                required: true,
                message: "Please enter a customer representative",
              },
            ]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Input placeholder="Enter a customer representative" />
            )}
          </Form.Item>

          <Form.Item
            label="UWI"
            name="uwi"
            rules={[{ required: true, message: "Please enter a UWI" }]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Input placeholder="Enter a UWI" />
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Posting Date"
            name="postDate"
            rules={[
              { required: true, message: "Please select a posting date" },
            ]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <DatePicker style={{ width: "100%" }} />
            )}
          </Form.Item>

          <Form.Item
            label="Due Date"
            name="dueDate"
            rules={[{ required: true, message: "Please select a due date" }]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <DatePicker style={{ width: "100%" }} />
            )}
          </Form.Item>

          <Form.Item
            label="Operation"
            name="operation"
            rules={[{ required: true, message: "Please enter an operation" }]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Input placeholder="Enter an operation" />
            )}
          </Form.Item>

          <Form.Item
            label="Salesperson"
            name="sales_person"
            rules={[{ required: true, message: "Please select a salesperson" }]}
          >
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Select
                options={formData.salespersons}
                fieldNames={{ label: "Name", value: "Code" }}
                onSelect={(value) => {
                  const selectedOption = formData.salespersons.find(
                    (option) => option.Code === value
                  );
                  form.setFieldsValue({
                    salespersonDetails: selectedOption,
                    salesperson_name: selectedOption.Name,
                  });
                }}
                showSearch
                placeholder="Select a salesperson"
                filterOption={(inputValue, option) =>
                  option.Name.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0
                }
              />
            )}
          </Form.Item>

          <Form.Item label="AFE" name="afe">
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Input placeholder="Enter an AFE" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Comments" name="comment">
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <Input.TextArea showCount maxLength={100} />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default TicketInformation;
