import React, { useEffect, useState } from "react";
import { Form, Steps, Spin } from "antd";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/Button";

import { useLocation } from "react-router-dom";
import "./EditTicket.style.css";
import { connect } from "react-redux";
import {
  FileAddOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { v4 as uuidv4 } from "uuid";

import TicketInformation from "./ticket-information/TicketInformation.component";
import ProductSelection from "./product-selection/ProductSelection.component";
import OpenInvoice from "./open-invoice/OpenInvoice.component";
import ReviewAndPrint from "./review-and-print/ReviewAndPrint.component";
import {
  fetchTicketFormData,
  updateTicketInformation,
  submitTicket,
} from "./EditTicketPage.service";
import generatePdf from "../../functions/generatePdf";

import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ServerErrorResult from "../../components/server-error-result/ServerErrorResult.component";

const EditTicket = ({ user }) => {
  const history = useHistory();
  const location = useLocation();
  const mode = location.state?.mode || "create";
  const ticketData = location.state?.ticketData
    ? formatDates(location.state?.ticketData)
    : { postDate: dayjs(), dueDate: dayjs() }; //Set default dates

  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [posting, setPosting] = useState(false);
  const [lastLineNumber, setLastLineNumber] = useState(0);

  const formDataQuery = useQuery({
    queryKey: ["ticketFormData"],
    queryFn: () =>
      fetchTicketFormData().then((result) => {
        if (mode === "edit") handleFetchDataResult(result, form, ticketData);
        return result;
      }),
  });

  const isLoading = formDataQuery.isPending;

  useEffect(() => {
    if (formDataQuery.isSuccess) {
      //Get last line number from selected products and comments if editing
      const formValues = form.getFieldsValue(true);

      let selectedProducts = formValues.selectedProducts || [];
      let comments = formValues.comments || [];

      // Assign keys to selectedProducts and comments
      selectedProducts = selectedProducts.map((p, index) => ({
        key: p.key || uuidv4(),
        ...p,
      }));
      comments = comments.map((c, index) => ({
        key: c.key || uuidv4(),
        ...c,
      }));

      // Set the form values with the new selectedProducts and comments
      form.setFieldsValue({ selectedProducts, comments });

      const maxLineNumber = Math.max(
        ...selectedProducts.map((p) => p.LineNumber),
        ...comments.map((c) => c.LineNumber),
        0
      );

      setLastLineNumber(maxLineNumber);
    }
  }, [formDataQuery.isSuccess, form]);

  // Function to find a customer by name in the customers array
  const findCustomerByName = (customers, customerName) => {
    return customers.find((customer) => customer.Name === customerName);
  };

  // Function to set the customer field in the form
  const setCustomerFormField = (form, customer) => {
    form.setFieldsValue({ customer });
  };

  // Function to handle errors and loading state
  const handleFetchDataResult = (result, form, ticketData) => {
    try {
      if (result) {
        const customer = findCustomerByName(
          result.customers,
          ticketData.customerName
        );

        if (customer) {
          setTimeout(() => {
            setCustomerFormField(form, customer);
          }, 0);
        } else {
          console.error(
            `No customer found with name: ${ticketData.customerName}`
          );

          // If no matching customer is found, use the first customer in the array (if it exists)
          if (result.customers.length > 0) {
            const firstCustomer = result.customers[0];
            setTimeout(() => {
              setCustomerFormField(form, firstCustomer);
            }, 0);
          } else {
            // Array is empty
            console.error("No customers available in the array.");
          }
        }
      } else {
        throw new Error("Empty response from the server");
      }
    } catch (error) {
      toast.error("Unable to fetch form data. Please refresh the page.", {
        theme: "dark",
        position: "bottom-right",
        closeOnClick: "true",
        autoClose: 5000,
      });
    }
  };

  const steps = [
    {
      title: "Ticket Information",
      content: (
        <TicketInformation
          ticketData={ticketData}
          formData={formDataQuery.data}
          loading={isLoading}
          form={form}
        />
      ),
    },
    {
      title: "Product Selection",
      content: (
        <ProductSelection
          form={form}
          mode={mode}
          lastLineNumber={lastLineNumber}
          setLastLineNumber={setLastLineNumber}
        />
      ),
    },
    {
      title: "Open Invoice",
      content: <OpenInvoice />,
    },
    {
      title: "Review and Print",
      content: <ReviewAndPrint form={form} formData={formDataQuery.data} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const validateTicketInformation = () => {
    form
      .validateFields()
      .then((values) => {
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        toast.error("Required field(s) left empty.", {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });
      });
  };

  const validateProductSelection = () => {
    let selectedProducts = form.getFieldValue("selectedProducts");
    let allInventoryGreaterThanOne = true; // Flag to track inventory status

    for (let i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i].Inventory < 1) {
        allInventoryGreaterThanOne = false; // Set flag to false if any inventory is less than 1
      }
    }

    if (allInventoryGreaterThanOne) {
      setCurrent(current + 1); // If all products have inventory > 1, proceed to the next step
    } else {
      toast.error(
        "Selected products require quantities above zero. Please review and adjust.",
        {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        }
      );
    }
  };

  const next = () => {
    if (current === 0) {
      validateTicketInformation();
    } else if (current === 1) {
      validateProductSelection();
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const editTicket = async (form) => {
    try {
      const result = await updateTicketInformation(form);

      if (result) {
        toast.success("Ticket updated successfully.", {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });

        generatePdf(form);
        setPosting(false);
        history.push({
          pathname: "/",
        });
      }
    } catch (error) {
      setPosting(false);

      toast.error(error.message, {
        theme: "dark",
        position: "bottom-right",
        closeOnClick: "true",
        autoClose: 5000,
      });
    }
  };

  const createTicket = async (form) => {
    try {
      const result = await submitTicket(form);

      if (result) {
        toast.success("Ticket created successfully.", {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });
        generatePdf(form);
        setPosting(false);
        history.push({
          pathname: "/",
        });
      }
    } catch (error) {
      setPosting(false);

      toast.error(error.message, {
        theme: "dark",
        position: "bottom-right",
        closeOnClick: "true",
        autoClose: 5000,
      });
    }
  };

  const onFinish = async () => {
    if (form.getFieldsValue(true).selectedProducts.length > 0) {
      setPosting(true);
      if (mode === "edit") {
        await editTicket(form.getFieldsValue(true));
      } else if (mode === "create") {
        await createTicket(form.getFieldsValue(true));
      }
    } else {
      toast.error(
        "Unable to post ticket without products. Please adjust your selection.",
        {
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        }
      );
    }
  };

  return (
    <>
      <div
        className={`edit__ticket__page__container  `}
        style={{ width: "100%" }}
      >
        <div>
          <div className="animate__animated animate__fadeIn" style={{}}>
            {" "}
            {mode === "edit" ? (
              <div className="edit__ticket__title">
                <EditOutlined /> Edit Ticket
                {ticketData.ticketNumber
                  ? `- ${ticketData.ticketNumber}`
                  : null}
              </div>
            ) : (
              <div className="edit__ticket__title">
                <FileAddOutlined /> New Ticket
                {form.getFieldValue("ticketNumber")
                  ? ` - ${form.getFieldValue("ticketNumber")}`
                  : null}
              </div>
            )}
            <div className="edit__ticket__page__steps">
              <Steps current={current} items={items} />
            </div>
            {posting && <Spin fullscreen={true} />}
            <div className={`edit__ticket__page__content`}>
              {formDataQuery.isError ? (
                <ServerErrorResult
                  showReload
                  subTitle="We couldn't retrieve the form data at the moment. Please check your internet connection and try again. If the issue persists, contact support for assistance."
                />
              ) : (
                <>
                  <Form
                    layout="vertical"
                    form={form}
                    initialValues={ticketData}
                  >
                    <div className="step__content__container">
                      {steps[current].content}
                    </div>
                  </Form>
                  <div className="step__content__buttons">
                    {current > 0 && (
                      <Button
                        style={{
                          margin: "0 8px",
                        }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button onClick={() => next()} disabled={isLoading}>
                        Next
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button onClick={() => onFinish()}>Finish</Button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function formatDates(ticketData) {
  const formattedTicketData = { ...ticketData };

  formattedTicketData.postDate = dayjs(new Date(ticketData.postDate));
  formattedTicketData.dueDate = dayjs(new Date(ticketData.dueDate));
  formattedTicketData.service_date = dayjs(new Date(ticketData.service_date));

  return formattedTicketData;
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(EditTicket);
