import "./loading-screen.styles.css";

const LoadingScreen = (props) => {
  return (
    <div className="loading__container">
      <div className="container__content">
        <div className="container__spinner">
          <div className="sk-folding-cube">
            <div className="sk-cube1 sk-cube"></div>
            <div className="sk-cube2 sk-cube"></div>
            <div className="sk-cube4 sk-cube"></div>
            <div className="sk-cube3 sk-cube"></div>
          </div>
        </div>

        {/* <div className="container__tip animate-flicker">
          <h4>{props.tip ? props.tip : "Loading"}</h4>
        </div> */}
      </div>
    </div>
  );
};

export default LoadingScreen;
