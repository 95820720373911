import customAxios from "../../config/axiosConfig";
import ipaddress from "../../config/ipaddress";

export async function getSalesOrderLines(salesNo) {
  try {
    var result = await customAxios.get(
      `/nav/sales/line/getSalesOrderLine/${salesNo}`
    );

    return result.data.value;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
}

export async function getSalesOrder(salesNo) {
  try {
    var result = await customAxios.get(`/nav/sales/getSalesOrder/${salesNo}`);

    return result.data.value;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
}

export async function updateSalesOrderLines(data, webUserId, location) {
  try {
    var result = await customAxios.post(
      `/nav/sales/line/updateSalesOrderLines`,
      { data: data, webUserId: webUserId, location: location }
    );
    return result;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
}

export async function shipTicket(ticketNumber) {
  try {
    var result = await customAxios.post(`/shipTicket`, {
      ticketNumber: ticketNumber,
    });
    return result;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(`${data}`);
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
}
