import { useState, useEffect } from "react";
import { Skeleton, Form, Row, Col, Select, Input, DatePicker } from "antd";

const OpenInvoice = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Customer GL Code" name="customer_gl_code">
            {loading ? <Skeleton.Input active /> : <Input />}
          </Form.Item>
          <Form.Item label="Service Date" name="service_date">
            {loading ? (
              <Skeleton.Input active />
            ) : (
              <DatePicker style={{ width: "100%" }} />
            )}
          </Form.Item>

          <Form.Item label="Cost Center AFE" name="cost_center_AFE">
            {loading ? <Skeleton.Input active /> : <Input />}
          </Form.Item>

          <Form.Item label="Customer PO No." name="customer_PO_no">
            {loading ? <Skeleton.Input active /> : <Input />}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Work Order No" name="work_order_no">
            {loading ? <Skeleton.Input active /> : <Input />}
          </Form.Item>

          <Form.Item label="Well Name" name="well_name">
            {loading ? <Skeleton.Input active /> : <Input />}
          </Form.Item>

          <Form.Item
            label="Approver/Approver Code"
            name="approver_approver_code"
          >
            {loading ? <Skeleton.Input active /> : <Input />}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default OpenInvoice;
