import React, { useState, useEffect } from "react";
import { Input, Form } from "antd";
import Button from "../../components/button/Button";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import customAxios from "../../config/axiosConfig";

import { withRouter } from "react-router-dom";
import "./ResetPassword.style.css";
import logo from "../../assets/steelhausCA_logo1.png";
import backgroundImage from "../../assets/login_bg.png";

import { toast } from "react-toastify";

const ResetPasswordPage = withRouter(({ history, user }) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [validToken, setValidToken] = useState(false);
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    const id = toast.loading("Please wait...", {
      position: "bottom-right",
      theme: "dark",
    });
    customAxios
      .post("/updateUserPass", {
        password: values.password,
        token: token,
      })
      .then((result) => {
        if (result.data === false) {
          toast.update(id, {
            render: "Password reset failed. Please try again later.",
            type: "error",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });
        } else {
          toast.update(id, {
            render: "Password reset successful!",
            type: "success",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
            isLoading: false,
          });

          history.push("/");
        }
      });
  };

  useEffect(() => {
    const token = history.location.pathname.split("/ResetPassword/")[1];
    setToken(token);
    if (token) {
      setLoading(true);
      customAxios
        .post(`/validateToken`, {
          token: token,
        })
        .then((result) => {
          setValidToken(result.data);
          setLoading(false);
        });
    }
  }, []);
  return (
    <div style={{ width: "100%", height: "100%", display: "flex" }}>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",

          alignItems: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
          <img
            className="authentication__logo"
            src={logo}
            onClick={() => {
              history.push("/");
            }}
          ></img>
        </div>
        <div
          className="animate__animated animate__fadeIn"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            gap: "20px",
            height: "90%",
            justifyContent: "center",
          }}
        >
          {" "}
          <div>
            {" "}
            <h1
              style={{
                textAlign: "left",
                margin: 0,
              }}
            >
              Password Reset
            </h1>
          </div>
          <Form
            name="basic"
            form={form}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item
              labelCol={{ span: 24 }}
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 6,
                  message: "Password must be minimum 6 characters.",
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Enter your new password" />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24 }}
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  min: 6,
                  message: "Password must be minimum 6 characters.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm your new password" />
            </Form.Item>

            <Form.Item>
              <Button
                shape="round"
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Reset password
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          width: "50%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
});

export default ResetPasswordPage;
