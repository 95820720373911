import { useState, useEffect } from "react";
import { Descriptions, Input } from "antd";
import "./ReviewAndPrint.styles.css";
import { formatCurrency } from "../../../functions/formatCurrency";

import DragSortTable from "../../../components/drag-sort-table/DragSortTable.component";
import {
  calculateTotal,
  calculateDiscountAmount,
  calculateSalesTax,
  calculateTotalWithDiscount,
} from "../../../functions/salesCalculations";

import { sortByLineNumber } from "../../../functions/sortByLineNumber";

const ReviewAndPrint = ({ form }) => {
  const [discount, setDiscount] = useState(form.getFieldValue("discount") || 0);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    //Update form with charges
    form.setFieldsValue({
      subtotal: calculateTotal(form.getFieldValue("selectedProducts")),
      taxes: calculateSalesTax(
        calculateTotal(form.getFieldValue("selectedProducts")),
        discount
      ),
      total: calculateTotalWithDiscount(
        form.getFieldValue("selectedProducts"),
        discount
      ),
    });
  }, [discount]);

  useEffect(() => {
    // Create an array with all the products and comments
    const allData = [
      ...form.getFieldValue("selectedProducts"),
      ...form.getFieldValue("comments"),
    ];

    const updatedDataSource = sortByLineNumber(allData);

    // Update the dataSource state only if it has changed
    if (!arraysAreEqual(dataSource, updatedDataSource)) {
      setDataSource(updatedDataSource);
    }
  }, [
    form.getFieldsValue("selectedProducts"),
    form.getFieldsValue("comments"),
    dataSource,
  ]);

  const summaryItems = [
    {
      key: "1",
      label: "Due Date",
      children: String(form.getFieldValue("dueDate")),
    },
    {
      key: "2",
      label: "Ticket",
      children: form.getFieldValue("ticketNumber"),
    },
    {
      key: "3",
      label: "UWI",
      children: form.getFieldValue("uwi"),
    },
    {
      key: "4",
      label: "Customer",
      children: form.getFieldValue("customerName"),
    },
    {
      key: "5",
      label: "Address",
      children: `${form.getFieldValue("customer").Address} ${
        form.getFieldValue("customer").City
      }, ${form.getFieldValue("customer").Customer_Posting_Group}, ${
        form.getFieldValue("customer").Post_Code
      }`,
    },
    {
      key: "6",
      label: "Customer Rep",
      children: form.getFieldValue("customerRep"),
    },
    {
      key: "7",
      label: "Steelhaus Rep",
      children: form.getFieldValue("steelhausRep"),
    },
    {
      key: "8",
      label: "Operation",
      children: form.getFieldValue("operation"),
    },
  ];

  const chargesItems = [
    {
      key: "1",
      label: "Sub Total",
      children: formatCurrency(
        calculateTotal(form.getFieldValue("selectedProducts"))
      ),
    },
    {
      key: "2",
      label: "Discount",
      children: (
        <Input
          addonBefore="%"
          value={discount}
          onChange={(e) => {
            const newDiscount = e.target.value;
            setDiscount(newDiscount);
            form.setFieldsValue({ discount: newDiscount });
          }}
        />
      ),
    },
    {
      key: "3",
      label: "Discount Amount",
      children: formatCurrency(
        calculateDiscountAmount(
          calculateTotal(form.getFieldValue("selectedProducts") || 0),
          discount
        )
      ),
    },
    {
      key: "4",
      label: "Sales Taxes",
      children: formatCurrency(
        calculateSalesTax(
          calculateTotal(form.getFieldValue("selectedProducts")),
          discount
        )
      ),
    },
    {
      key: "5",
      label: "Total",
      children: formatCurrency(
        calculateTotalWithDiscount(
          form.getFieldValue("selectedProducts"),
          discount
        )
      ),
    },
  ];

  const dragSortColumns = [
    {
      key: "sort",
    },
    {
      title: "Part Number",
      dataIndex: "No",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Quantity",
      dataIndex: "Inventory",
    },
    {
      title: "Price",
      dataIndex: "Unit_Cost",
      render: (text, record) => {
        if (record.Unit_Cost) return formatCurrency(Number(record.Unit_Cost));
      },
    },
    {
      title: "UOM",
      dataIndex: "UOM",
    },
    {
      title: "Amount",
      render: (text, record) => {
        if (record.Unit_Cost && record.Inventory) {
          const amount = record.Inventory * record.Unit_Cost;
          return formatCurrency(amount);
        }
      },
    },
  ];

  function processObjectArray(objects) {
    let lineNumber = 1;

    // First, assign LineNumber to all objects
    const objectsWithLineNumbers = objects.map((obj) => {
      obj.LineNumber = lineNumber++;

      return obj;
    });

    // Now, split the objects into comments and non-comments
    const comments = objectsWithLineNumbers.filter(
      (obj) => obj.type === "comment"
    );
    const nonComments = objectsWithLineNumbers.filter(
      (obj) => obj.type !== "comment"
    );

    return { comments, nonComments };
  }

  function onDragSortChange(value) {
    // Update the dataSource state directly when the order changes
    setDataSource(value);

    const { comments, nonComments } = processObjectArray(value);

    form.setFieldsValue({
      comments,
      selectedProducts: nonComments,
    });
  }

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }

    return true;
  }

  return (
    <div className="review__and__print__container">
      <Descriptions
        title="Ticket Summary"
        items={summaryItems}
        column={1}
        bordered
        size="small"
      />
      <DragSortTable
        dataSource={dataSource}
        columns={dragSortColumns}
        onChange={(value) => {
          onDragSortChange(value);
        }}
      />
      <Descriptions
        title="Summary of Charges"
        items={chargesItems}
        column={1}
        bordered
        size="small"
      />
    </div>
  );
};

export default ReviewAndPrint;
