import { useState } from "react";
import Header from "../../components/header/header.component";
import FooterComponent from "../../components/footer/footer.component";
import FieldTicketTable from "../../components/field-ticket-table/FieldTicketTable.component";
import Button from "../../components/button/Button";
import { useHistory } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import ShipModal from "../../components/ship-modal/ship-modal.component";

import { fetchTickets } from "./homepage.service";
import { useQuery } from "@tanstack/react-query";
import { fetchTicketFormData } from "../edit-ticket/EditTicketPage.service";
import ServerErrorResult from "../../components/server-error-result/ServerErrorResult.component";
import LoadingScreen from "../../components/loading-screen/loading-screen.component";
import { toast } from "react-toastify";

import "./homepage.styles.css";

const HomePage = () => {
  const [shipModalVisible, setShipModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const history = useHistory();

  const ticketsQuery = useQuery({
    queryKey: ["tickets"],
    queryFn: fetchTickets,
  });

  const tableDropdownsQuery = useQuery({
    queryKey: ["ticketFormData"],
    queryFn: fetchTicketFormData,
  });

  const isLoading = ticketsQuery.isPending || tableDropdownsQuery.isPending;

  const isError = ticketsQuery.isError || tableDropdownsQuery.isError;

  const isPaused = ticketsQuery.isPaused || tableDropdownsQuery.isPaused;

  if (isPaused) {
    toast.error(
      "It seems that your network connection is currently unavailable. We'll automatically attempt to fetch the tickets again once the connection is reestablished.",
      {
        toastId: "networkError",
        position: "bottom-right",
        theme: "dark",
        autoClose: 10000,
      }
    );
  }

  return (
    <>
      <>
        <ShipModal
          visible={shipModalVisible}
          setVisible={setShipModalVisible}
          selectedRowData={selectedRow}
          onSuccess={() => {
            window.location.reload();
          }}
        ></ShipModal>
        <div
          className="home__page__container animate__animated animate__fadeIn"
          style={{ padding: "10px" }}
        >
          {isError ? (
            <ServerErrorResult
              showReload
              subTitle="We couldn't retrieve the tickets at the moment. Please check your internet connection and try again. If the issue persists, contact support for assistance."
            />
          ) : (
            <div className="home__page__content ">
              {isLoading ? (
                <LoadingScreen />
              ) : (
                <div
                  className="animate__animated animate__fadeIn"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {" "}
                  <div className="home__page__actions ">
                    <Button
                      icon={<FormOutlined />}
                      onClick={() => {
                        history.push({
                          pathname: "./create-ticket",
                          state: {
                            mode: "create",
                          },
                        });
                      }}
                    >
                      CREATE FIELD TICKET
                    </Button>
                  </div>
                  <FieldTicketTable
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setShipModalVisible={setShipModalVisible}
                    dropdownData={tableDropdownsQuery.data}
                    recordData={ticketsQuery.data}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </>
    </>
  );
};

export default HomePage;
