import { React, useEffect, useState } from "react";

import Header from "../../components/header/header.component";
import FooterComponent from "../../components/footer/footer.component";

import customAxios from "../../config/axiosConfig";

import { Form, Button, Modal, Popconfirm, Select } from "antd";

import { Tag } from "antd";

import { Spin } from "antd";
import { Layout } from "antd";
import Groupmodal from "../../components/salesperson-modal/SalespersonModal.component.jsx";
import "./salespersonGroups.styles.css";
import ipaddress from "../../config/ipaddress";

import { toast } from "react-toastify";
var randomColor = require("randomcolor"); // import the script

const SalespersonGroups = () => {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [groups, setgroups] = useState([]);
  const [users, setusers] = useState();
  const [componentSize, setComponentSize] = useState("default");
  const [customer, setcustomer] = useState([]);
  const [groupname, setgroupname] = useState("");
  const [selectcust, setselectcust] = useState("");
  const [selectcustomer_No, setselectcustomer_No] = useState();
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    setgroupname("");
  }, [visible]);
  const [form] = Form.useForm();
  const [selecteduser, setselecteduser] = useState([]);
  const [loadcust, setloadcust] = useState(true);
  const [spinning, setspinning] = useState(false);
  function onChangeuser(e) {
    //var userObject = JSON.parse(e);

    setselecteduser(e);
  }
  function onChangecustomer(value, e) {
    setselectcust(value);

    setselectcustomer_No(e.key);
  }
  function onChangeGroupName(value, e) {
    setgroupname(value.target.value);
  }
  useEffect(() => {
    var color = randomColor();
    customAxios
      .get(`/getspusers`)
      .then(async (response) => {
        setusers(await response.data);
        setloadcust(false);
      })
      .catch((error) => {
        toast.error(
          "Could not get list of salespeople. Please try again later.",
          {
            toastId: "salespeopleError",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
          }
        );
      });

    customAxios
      .get(`/customerlist`)
      .then((res) => {
        setspinning(true);
        const formreview_data = res.data;

        setloadcust(false);
        getgroups();
        setcustomer(formreview_data);
      })
      .catch((error) => {
        setloadcust(false);
        getgroups();
        toast.error("Could not get customer list. Please try again later.", {
          toastId: "customerListError",
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });
      });
  }, []);
  useEffect(() => {});

  const savetodatabase = () => {};

  const [refreshg, setrefreshg] = useState(false);
  const handleOk = () => {
    let data = {
      groupname: groupname,
      customername: selectcust,
      users: selecteduser,
    };
    customAxios
      .post(`/creategroup`, {
        data,
      })
      .then((response) => {
        getgroups();
        setVisible(false);
        setConfirmLoading(false);
        setgroupname("");
        setrefreshg(true);
        setselectcust("");
        setselecteduser([]);
        setselectcust("");
      })
      .catch((error) => {
        toast.error(
          "Could not create new salesperson group. Please try again later.",
          {
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
          }
        );
      });
  };

  let initialTxt = "Salesperson Groups";
  const [text, setText] = useState(initialTxt);
  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    getgroups();
  }, []);

  const getgroups = () => {
    customAxios
      .get(`/groups`)
      .then((res) => {
        setgroups(res.data);
        setloaded(true);
        setrefresh(false);
      })
      .catch((error) => {
        toast.error(
          "Could not get list of sales groups. Please try again later.",
          {
            toastId: "salesGroupError",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
          }
        );
        setloaded(true);
        setrefresh(false);
      });
  };
  useEffect(() => {
    getgroups();
    setrefreshg(false);
  }, [refreshg]);

  useEffect(() => {
    var color = randomColor();
    customAxios
      .get(`/getspusers`)
      .then(async (response) => {
        setusers(await response.data);
        setloadcust(false);
      })
      .catch((error) => {
        toast.error(
          "Could not get list of salespeople. Please try again later.",
          {
            toastId: "salespeopleError",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
          }
        );
      });

    customAxios
      .get(`/customerlist`)
      .then((res) => {
        setspinning(true);
        const formreview_data = res.data;
        getgroups();
        setloadcust(false);

        setcustomer(formreview_data);
      })
      .catch((error) => {
        setloadcust(false);
        getgroups();
        toast.error("Could not get customer list. Please try again later.", {
          toastId: "customerListError",
          theme: "dark",
          position: "bottom-right",
          closeOnClick: "true",
          autoClose: 5000,
        });
      });
  }, [refreshg]);
  const [loaded, setloaded] = useState(false);
  const [refresh, setrefresh] = useState(false);

  const confirm = (group) => {
    customAxios
      .post(`/deletegroup`, {
        groupid: group.groupid,
        customername: group.customername,
      })
      .then((res) => {
        getgroups();
        setrefreshg(true);

        setrefresh(true);
        getgroups();
      })
      .catch((error) => {
        toast.error(
          "Could not delete salesperson group. Please try again later.",
          {
            toastId: "customerListError",
            theme: "dark",
            position: "bottom-right",
            closeOnClick: "true",
            autoClose: 5000,
          }
        );
      });
  };

  const [passdata, setpassdata] = useState();
  const [visible1, setvisible1] = useState(false);
  const [groupvisible, setgroupvisible] = useState(false);

  useEffect(() => {
    getgroups();
  }, [groupvisible]);
  return (
    <div className="salesperson__groups__container">
      {" "}
      <>
        <div style={{ display: "inline-block" }}>
          <div className="groupsales_header" style={{ float: "left" }}>
            {" "}
            Salesperson Groups
          </div>
          <div style={{ float: "right" }}>
            <Button
              className="btn-hover color-9"
              onMouseOver={() => setText("New Sales Group")}
              onMouseLeave={() => setText(initialTxt)}
              type="primary"
              onClick={() => {
                setgroupname("");
                showModal();
              }}
            >
              {" "}
              New Sales Group
            </Button>{" "}
          </div>
        </div>
        <div className="block">
          {loaded ? (
            groups.length > 0 ? (
              groups?.map((group, idx) => {
                return (
                  <>
                    <div className="group_component">
                      <div className="info_component">
                        <div className="group_company">
                          <span className="sideheader">
                            {" "}
                            Company Name:{" "}
                            <span className="value">
                              {" "}
                              {group.customer_name}
                            </span>
                          </span>
                        </div>
                        <div className="group_username">
                          <span className="sideheader"> Users: </span>
                          {group.salespersons ? (
                            group.salespersons.split(",").map((user) => {
                              return (
                                <>
                                  <Tag>{user ? user : "No User"}</Tag>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          {" "}
                          <Popconfirm
                            title="Are you sure delete this Group?"
                            onConfirm={() => confirm(group)}
                            cancelText="No"
                          >
                            <Button>Delete Group</Button>
                          </Popconfirm>
                          <Button
                            style={{ marginLeft: "1rem" }}
                            type="primary"
                            onClick={() => {
                              setpassdata(group);
                              setvisible1(true);
                              setgroupvisible(true);
                            }}
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>
                <h1>No Groups</h1>
              </>
            )
          ) : (
            <></>
          )}
        </div>

        {groupvisible ? (
          <Groupmodal
            group={passdata}
            visible={visible1}
            setgroupvisible={setgroupvisible}
            setvisible={setvisible1}
          ></Groupmodal>
        ) : (
          <></>
        )}

        <div className="modalstyle">
          <Modal
            width={800}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <br></br>
            <div>
              {" "}
              <label>Customer Name: </label>
              <Select
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                className="customer3"
                placeholder="Select a Customer"
                optionFilterProp="children"
                onChange={onChangecustomer}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={selectcust}
              >
                {!loadcust ? (
                  <>
                    {customer
                      .filter((elem) => {
                        return !groups
                          .map((elemGroup) => {
                            return elemGroup.customer_name;
                          })
                          .includes(elem.Name);
                      })
                      .map((item) => {
                        return (
                          <>
                            {item.Name.length > 1 ? (
                              <Option value={item.Name} key={item.No}>
                                {item.Name}
                              </Option>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}{" "}
                  </>
                ) : (
                  <p style={{ color: "blue" }}>
                    {" "}
                    <Spin fullscreen={true} />
                  </p>
                )}
              </Select>
            </div>
            <br></br>
            <div>
              {" "}
              <label> Select Salespersons: </label>
              <Select
                label="loaind"
                mode="multiple"
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                className="customer3"
                placeholder="Select a User"
                optionFilterProp="children"
                onChange={onChangeuser}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={selecteduser}
              >
                {!loadcust ? (
                  <>
                    {users?.map((item) => {
                      return (
                        <>
                          <Option key={item.fullName} value={item.emailAddress}>
                            {item.fullName}
                          </Option>
                        </>
                      );
                    })}{" "}
                  </>
                ) : (
                  <p style={{ color: "blue" }}>
                    {" "}
                    <Spin fullscreen={true} />
                  </p>
                )}
              </Select>
            </div>
          </Modal>
        </div>
      </>
    </div>
  );
};

export default SalespersonGroups;
