import { useState } from "react";
import { Input } from "antd";
const { TextArea } = Input;

const EditableCell = ({
  value,
  onChange,
  showCount = false,
  type = "input",
}) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const toggleEdit = () => {
    setEditing(!editing);
    setInputValue(value);
  };

  const handleSave = () => {
    setEditing(false);
    if (inputValue !== value) {
      onChange(inputValue);
    }
  };

  return editing ? (
    <div>
      {type === "input" ? (
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleSave}
          onBlur={handleSave}
          style={{ width: "80%" }}
          maxLength={100}
          showCount={showCount}
        />
      ) : (
        <TextArea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleSave}
          onBlur={handleSave}
          style={{ width: "80%" }}
          maxLength={100}
          showCount={showCount}
          rows={4}
        />
      )}
    </div>
  ) : (
    <div onClick={toggleEdit} style={{ cursor: "pointer", color: "#3498db" }}>
      {inputValue}
    </div>
  );
};

export default EditableCell;
