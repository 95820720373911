import { Descriptions } from "antd";
import { Divider } from "primereact/divider";

import "./summary-descriptions.styles.css";

const SummaryDescriptions = (props) => {
  const summary = props.summaryData ? props.summaryData : {};

  return (
    <div className="summary__container">
      <p className="summary__text">Summary</p>
      <Divider></Divider>
      <div className="summary__descriptions">
        <Descriptions bordered size="small">
          <Descriptions.Item label="Ticket #">
            {summary?.No || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Customer">
            {summary?.Ship_to_Name || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Post Date">
            {summary?.Posting_Date || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Due Date">
            {summary?.Due_Date || "N/A"}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default SummaryDescriptions;
