import {
  CONNECT_WITH_SSO,
  AppAction,
  AppState,
  SHOW_SECRET,
  DISCONNECT,
  CONNECT_WITH_BAD_CREDENTIALS,
  CHECK_INDICATOR,
  UPDATE_REVENUES,
} from "./types";

export const initialState: AppState = {
  secret: "",
  error: false,
};

export function reducer(
  previousState = initialState,
  action: AppAction
): AppState {
  const state = { ...previousState };
  state.error = false;
  switch (action.type) {
    case CHECK_INDICATOR: {
      state.check = action.checked;

      return state;
    }

    case CONNECT_WITH_SSO: {
      state.user = action.user;

      return state;
    }

    case UPDATE_REVENUES: {
      state.revenue = action.revenue;

      return state;
    }

    case CONNECT_WITH_BAD_CREDENTIALS: {
      state.error = true;

      return state;
    }
    case SHOW_SECRET: {
      state.secret = action.secret;

      return state;
    }
    case DISCONNECT: {
      state.secret = "";
      state.user = undefined;
      return state;
    }

    default:
      return previousState;
  }
}
