import { useState, useEffect } from "react";
import { WifiOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import "./OnlineIndicator.styles.css";

const OnlineIndicator = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return (
    <div
      className={`online__indicator__container${
        isOnline ? "--online" : "--offline"
      }`}
    >
      {" "}
      <Avatar
        shape="square"
        icon={
          <WifiOutlined
            style={{
              color: isOnline ? "rgb(46, 204, 113)" : "#ff4d4f",
            }}
          />
        }
        style={{
          backgroundColor: "white",
        }}
        size="small"
      />{" "}
      {isOnline ? (
        <p className="online__indicator__label">Online</p>
      ) : (
        <p className="online__indicator__label">Offline</p>
      )}
    </div>
  );
};
export default OnlineIndicator;
