import { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
import {
  CloseCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";

import "./ship-modal.styles.css";
import CartTable from "./cart-table/cart-table.component";
import SummaryDescriptions from "./summary-descriptions/summary-descriptions.component";
import LoadingScreen from "../loading-screen/loading-screen.component";
import ErrorScreen from "./error-screen/error-screen.component";
import {
  getSalesOrder,
  getSalesOrderLines,
  updateSalesOrderLines,
  shipTicket,
} from "./ship-modal.services";
import CompletedScreen from "./completed-screen/completed-screen.component";

const ShipModal = ({
  visible,
  selectedRowData,
  setVisible,
  onSuccess = () => {},
}) => {
  const [posting, setPosting] = useState(false);
  const [loadingSalesOrder, setLoadingSalesOrder] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showError, setShowError] = useState(false);
  const [salesLines, setSalesLines] = useState([]);
  const [salesOrder, setSalesOrder] = useState([]);
  const [shipmentComplete, setShipmentComplete] = useState(false);
  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
    //Get Sales Order Lines
    setLoadingSalesOrder(true);
    if (visible) {
      if (selectedRowData || forceReload) {
        getSalesOrder(selectedRowData.ticketNumber)
          .then((salesOrder) => {
            setSalesOrder(salesOrder[0]);

            getSalesOrderLines(selectedRowData.ticketNumber)
              .then((salesLines) => {
                if (checkIfComplete(salesLines) === true) {
                  setShipmentComplete(true);
                  shipTicket(selectedRowData.ticketNumber);
                } else {
                  setShipmentComplete(false);
                }
                setSalesLines(filterSalesLines(salesLines));
                setLoadingSalesOrder(false);
                setForceReload(false);
              })
              .catch((error) => {
                toast.error(
                  "Could not get sales order lines. Please try again later.",
                  {
                    theme: "dark",
                    position: "bottom-right",
                    closeOnClick: "true",
                    autoClose: 5000,
                  }
                );
              });
          })
          .catch((error) => {
            toast.error("Could not get sales order. Please try again later.", {
              theme: "dark",
              position: "bottom-right",
              closeOnClick: "true",
              autoClose: 5000,
            });
          });
      }
    }
  }, [selectedRowData, forceReload, visible]);

  //Remove all comments from sales lines
  function filterSalesLines(salesLines) {
    var filtered = [];
    for (var i = 0; i < salesLines.length; i++) {
      if (salesLines[i].FormatType === "Item") {
        filtered.push(salesLines[i]);
      }
    }
    return filtered;
  }

  function checkIfComplete(salesLines) {
    var complete = true;

    for (var i = 0; i < salesLines.length; i++) {
      if (salesLines[i].QtyOutstanding > 0) {
        complete = false;
      }
    }
    return complete;
  }

  function doShipment() {
    setPosting(true); //Set loading state
    var webUserId = localStorage.getItem("username");

    updateSalesOrderLines(salesLines, webUserId, salesOrder.Location_Code).then(
      (res) => {
        setPosting(false);
        if (res.data.errors.length > 0) {
          //Errors present
          setErrors(res.data.errors);
          setShowError(true);
        } else {
          //Success, no errors present

          shipTicket(selectedRowData.ticketNumber).then(() => {
            showSuccess();
            setVisible(false);
            onSuccess();

            setForceReload(true);
          });
        }
      }
    );
  }

  function showSuccess() {
    toast.success("Posted shipment to Business Central.", {
      theme: "dark",
      position: "bottom-right",
      closeOnClick: "true",
      autoClose: 5000,
    });
  }

  //Header renderer
  const header = () => {
    return (
      <div className="ship__header">
        <div className="header__container--text">
          <p className="header__text animate__animated animate__fadeInLeft">
            Shipment
          </p>
        </div>
        <div className="header__exit">
          <div
            className="close__button"
            onClick={() => {
              setVisible(false);
              setShowError(false);
              setPosting(false);
            }}
          >
            {" "}
            <CloseCircleOutlined />
          </div>
        </div>
      </div>
    );
  };

  //Modal Content Renderer
  const content = () => {
    return (
      <>
        {showError ? (
          <ScrollPanel
            style={{ width: "100%", height: "100%" }}
            className="ship__scroll--custom animate__animated animate__fadeIn"
          >
            <ErrorScreen errors={errors}></ErrorScreen>{" "}
          </ScrollPanel>
        ) : (
          scrollContent()
        )}
      </>
    );
  };

  const scrollContent = () => {
    return (
      <ScrollPanel
        style={{ width: "100%", height: "100%" }}
        className="ship__scroll--custom animate__animated animate__fadeIn"
      >
        {loadingSalesOrder ? (
          <LoadingScreen tip="Loading Sales Order"></LoadingScreen>
        ) : (
          ticketDetails()
        )}
      </ScrollPanel>
    );
  };

  const ticketDetails = () => {
    return (
      <>
        {shipmentComplete ? (
          <>
            <CompletedScreen></CompletedScreen>
          </>
        ) : (
          <></>
        )}
        <SummaryDescriptions summaryData={salesOrder}></SummaryDescriptions>
        <CartTable cartData={salesLines}></CartTable>
      </>
    );
  };

  //Footer renderer
  const footer = () => {
    return (
      <div className="ship__footer">
        {posting || loadingSalesOrder ? <></> : footerButton()}
      </div>
    );
  };

  const footerButton = () => {
    return (
      <div
        className="footer__button"
        onClick={() => {
          setShowError(false);
        }}
      >
        {showError ? (
          <div className="back__button">
            <LeftCircleOutlined />
          </div>
        ) : (
          nextButton()
        )}
      </div>
    );
  };

  const nextButton = () => {
    return (
      <>
        {shipmentComplete ? (
          <></>
        ) : (
          <div
            className="next__button"
            onClick={() => {
              doShipment();
            }}
          >
            <RightCircleOutlined />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="custom__dialog">
      <Dialog
        showHeader={false}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "75vw", height: "85vh", borderRadius: "20px" }}
        draggable={false}
        position="center"
        closable={false}
        contentStyle={{ padding: "0", borderRadius: "20px" }}
      >
        {header()}
        <div className="dialog__content">
          {posting ? (
            <LoadingScreen tip="Posting To NAV"></LoadingScreen>
          ) : (
            content()
          )}
        </div>

        {footer()}
      </Dialog>
    </div>
  );
};

export default ShipModal;
