import customAxios from "../../config/axiosConfig";

export const fetchTickets = async () => {
  try {
    const response = await customAxios.get(`/ticket`);

    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(
        `Request failed with status: ${status}\n${JSON.stringify(data)}`
      );
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received: ${error.request}`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const consolidateTicketInformation = async () => {
  try {
    const response = await customAxios.get(`/ticket/consolidate/allTickets`);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with an error status code
      const { status, data } = error.response;
      throw new Error(
        `Request failed with status: ${status}\n${JSON.stringify(data)}`
      );
    } else if (error.request) {
      // Request made, no response
      throw new Error(`No response received: ${error.request}`);
    } else {
      // Error setting up or performing the request
      throw new Error(`Error: ${error.message}`);
    }
  }
};
